import React, { useEffect, useState } from 'react'
import PageLayout from '../../../Layout/PageLayout/Index'
import SEOPageHero from './SEOPageHero/Index'
import SEOPricing from '../../../Components/SEOPricing/Index'
import mixpanel from 'mixpanel-browser'
import Process from '../../../Components/Process/Index'
import { SiGoogle, SiGoogleanalytics, SiGooglesearchconsole, SiHtml5 } from '@icons-pack/react-simple-icons'
import { LinkIcon } from '@heroicons/react/24/outline'
import SEOPricingTable from '../../../Components/PricingTables/SEOPricingTable/Index'
import SearchEngineSlider from '../../../Components/SearchEngineSlider/Index'
import Slider from '../../../Components/Slider/Index'
import CalendarModal from '../../../Layout/CalendarModal/Index'
import LeadsForm from '../../../Components/LeadsForm/Index'
import NationalSEOPricing from '../../../Components/SEOPricing/National/Index'
import SEOFAQs from '../../../Components/FAQs/SEOFAQs/Index'
import Newsletter from '../../../Components/Newsletter/Index'
import SEOMetaHead from '../../../Components/SEOMetaHead/Index'

const SEOServicePage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("SEO Service Page Visited")
    }, [])

    return (
        <PageLayout>
            <SEOMetaHead
                title="Expert Search Engine Optimization Services - Enhanced Rankings in 2024"
                description="Maximize your SEO outcomes with Gurulabs' professional optimization. Boost your search visibility by 30% and achieve top Google rankings. Contact us for premier SEO solutions."

            />
            <SEOPageHero />
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what seo services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <NationalSEOPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                Search Engine Optimization Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="SEO Audit"
                            description="Starting with a comprehensive SEO audit, Gurulabs evaluates your current website to identify strengths, weaknesses, and opportunities for optimization. Our detailed assessment covers technical SEO, content, and backlink profiles to establish a baseline and craft a strategy that targets your specific business objectives and market position."
                            button="button-3"
                            Icon={SiGooglesearchconsole}
                        />
                        <Process
                            title="Keyword Research and Strategy"
                            description="Gurulabs performs extensive keyword research to understand the search landscape within your industry. We identify high-value keywords based on relevance, search volume, and competition level, forming a strategic foundation that drives content creation and optimization efforts to attract and engage your target audience effectively."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                        <Process
                            title="On-page SEO Optimization"
                            description="Our team meticulously optimizes on-page elements, including title tags, meta descriptions, headers, and images, ensuring they align with targeted SEO strategies. Gurulabs enhances your site’s usability and relevance, improving your search engine rankings while delivering a seamless user experience."
                            button="button-3"
                            Icon={SiGoogle}
                        />
                        <Process
                            title="Content Development"
                            description="Content is king in SEO, and Gurulabs specializes in creating SEO-driven content that not only ranks but resonates with your audience. From blog posts to whitepapers, we produce high-quality, engaging content tailored to your brand’s voice and user intent, positioning your business as a thought leader in your industry."
                            button="button-2"
                            Icon={SiHtml5}
                        />
                        <Process
                            title="Link Building Strategy"
                            description="Gurulabs uses a white-hat approach to link building, securing high-quality backlinks from authoritative websites to boost your site's authority and search engine rankings. Our strategies include guest blogging, influencer collaborations, and strategic partnerships, enhancing your online presence and driving organic traffic."
                            button="button-3"
                            Icon={LinkIcon}
                        />
                        <Process
                            title="Performance Tracking and Reporting"
                            description="With Gurulabs, transparency is paramount. We track the progress of your SEO campaign using advanced analytics tools to monitor key performance indicators like traffic, rankings, and conversions. Regular reports keep you informed of your SEO investment's impact, enabling continuous improvement and strategy refinement."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />

                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're an affordable</span>
                                    <span className="text-green-500 ml-2 relative">
                                        Search Engine Optimization agency
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">Our <a href="//www.gurulabs.dev/blog/finding-search-engine-optimization-agency-near-washington-dc" className='font-bold underline'>SEO management</a> process helps you identify your target audience, optimize your website and content strategies, and gather valuable insights to increase organic traffic and maximize your return on investment.</p>

                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we are committed to enhancing your brand's visibility and driving your website's traffic through customized SEO strategies. Our team uses the latest techniques and tools to ensure your SEO campaign is both effective and efficient. With Gurulabs, you receive a comprehensive SEO management experience that aims to maximize your search engine rankings and ROI.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Streamlined SEO Management Process</strong></p>

                                <p className="text-xl py-4 text-gray-700">Our SEO services are tailored to streamline your digital presence. Starting with a detailed analysis of your current SEO health, Gurulabs identifies key areas for improvement. Our approach is rooted in a data-driven methodology, utilizing state-of-the-art tools to continually refine and optimize your SEO strategies. Whether you're building a new SEO framework or enhancing an existing one, our services are designed to meet your unique needs.</p>

                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we recognize the importance of targeted optimization. Our team specializes in crafting customized SEO strategies that align with your specific business objectives. With Gurulabs, expect precise keyword targeting, strategic content planning, and rigorous SEO audits. We handle everything from on-page optimization to complex technical adjustments, ensuring your SEO strategy is comprehensive and effective.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Expertise Across All SEO Dimensions</strong></p>

                                <p className="text-xl py-4 text-gray-700">Gurulabs is not just any SEO agency. We possess deep expertise in all facets of SEO, from technical SEO that ensures your site meets the latest search engine guidelines to content SEO that engages and converts. Our holistic approach extends to local SEO and international SEO, helping you dominate both domestic and global search engine results.</p>

                                <p className="text-xl py-4 text-gray-700">Our comprehensive SEO management services ensure every aspect of your digital presence is optimized for success. From in-depth keyword research to the development of engaging content and authoritative link building, we cover all bases to elevate your SEO strategy. Our goal is to deliver exceptional SEO services that not only meet but exceed your expectations.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Customized Solutions for Diverse Business Needs</strong></p>

                                <p className="text-xl py-4 text-gray-700">Every business is unique, which is why we offer tailored SEO solutions designed to cater specifically to your industry and target audience. Whether you need a focus on ecommerce SEO to enhance product visibility or require a robust strategy for B2B lead generation, Gurulabs has the expertise to support your goals. Our team collaborates closely with you to understand your objectives and devise a strategy that delivers real results.</p>

                                <p className="text-xl py-4 text-gray-700">We believe that effective SEO management involves continuous learning and adaptation. Our experts stay ahead of the latest industry trends and algorithm updates, ensuring your SEO campaigns are always at the forefront of digital marketing innovations. With Gurulabs, you partner with a team dedicated to your continuous growth and success in the online world.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Maximizing Your Search Engine Visibility and ROI</strong></p>

                                <p className="text-xl py-4 text-gray-700">Success in SEO is measured by tangible results. At Gurulabs, our focus is on maximizing your visibility in search engines and ensuring a high return on investment. We employ a mix of proven strategies and innovative techniques to make your website not only visible but also predominant in its field. With Gurulabs, you can rest assured that your SEO investment is in good hands, driving significant traffic and generating valuable leads.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, choosing Gurulabs for SEO management means opting for a full suite of services that simplify and enhance your online presence. From detailed analysis to ongoing optimization, we provide comprehensive SEO solutions that deliver lasting results. Partner with Gurulabs today and propel your website to the top of search engine rankings.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <SEOPricingTable />
            <Newsletter />
            <SEOFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <SearchEngineSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
            {/* <Popup /> */}
        </PageLayout>
    )
}
export default SEOServicePage
