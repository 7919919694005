import React, { useEffect, useRef, useState } from 'react'
import PageLayout from '../../Layout/PageLayout/Index'
import MetaHead from '../../Components/MetaHead'
import { format, formatDistanceToNowStrict, parseISO } from 'date-fns'
import mixpanel from 'mixpanel-browser';
import Tags from '../../Components/Tags/Index'
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    RedditShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    RedditIcon,
    EmailShareButton,
    EmailIcon
} from 'react-share'
import { PlayCircleIcon, StopCircleIcon } from '@heroicons/react/24/outline';
import ReactDisqusComments from 'react-disqus-comments';
import { Inertia } from '@inertiajs/inertia';


const BlogShowPage = ({ post, tags, recentPosts }) => {
    const author = post.author;
    const meta = post.meta;
    const url = post.slug ? 'https://www.gurulabs.dev/blog/' + post.slug : window.location.href;
    const title = post.title;
    const media = post.media;
    const speechInstance = useRef(null);
    const disqusShortname = 'gurulab';
    const identifier = post.id;

    useEffect(() => {
        if (!post.published) {
            Inertia.visit('/404');
            return;
        }
        mixpanel.track(`${title} Page Visited`)
    }, [post.published])

    if (!post.published) {
        return null;
    }

    return (
        <PageLayout>
            <MetaHead
                title={
                    meta.opengraph_title ||
                    meta.twitter_title ||
                    `${post.title} - Gurulabs`
                }
                description={
                    meta.meta_description ||
                    meta.opengraph_description ||
                    meta.twitter_description ||
                    post.excerpt
                }
                image={meta.opengraph_image || meta.twitter_image || media}
                url={url}
                type='article'
            />
            <main className="flex-1 w-full px-4 mt-16" id="main" role="main">
                <div
                    className={`flex flex-col max-w-screen-lg mx-auto my-8 space-y-8 lg:my-16 lg:space-y-16 justify-content`}
                >
                    <div className="flex flex-col space-y-4 md:items-center md:text-center">
                        <h1 className="text-4xl lg:text-6xl">
                            {post.title}
                        </h1>
                        <div className="flex md:items-center">
                            <Tags tags={post.tags} />
                        </div>
                        <div>
                            <time
                                className="block text-sm text-gray-600"
                                dateTime={format(
                                    new Date(parseISO(post.created_at)),
                                    'MM/dd/yyyy',
                                )}
                            >
                                Published <strong>{formatDistanceToNowStrict(new Date(parseISO(post.created_at)), new Date(), {
                                    addSuffix: true,
                                })}</strong> ago
                            </time>
                        </div>
                    </div>

                    <article
                        className="prose md:prose-lg lg:prose-xl pb-8 pt-8 border-y-2 border-gray-200"
                        dangerouslySetInnerHTML={{
                            __html: post.body,
                        }}
                    ></article>
                    {author?.name && (
                        <div className="flex items-center space-x-4 border-t border-b border-gray-200 py-6">
                            <img src={author.avatar} alt={author.name} className="w-16 h-16 rounded-full border border-gray-300" />
                            <div>
                                <p className="text-lg font-semibold">{author.name}</p>
                                <div className="text-sm text-gray-600" dangerouslySetInnerHTML={{ __html: author.bio }}></div>
                            </div>
                        </div>
                    )}
                    <ReactDisqusComments
                        shortname={disqusShortname}
                        identifier={identifier}
                        title={title}
                        url={url}
                    />
                </div>
            </main>
        </PageLayout>
    )
}
export default BlogShowPage
