import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import MetaHead from '../../../../Components/MetaHead'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import SEOPricing from '../../../../Components/SEOPricing/Index'
import Slider from '../../../../Components/Slider/Index'
import CalendarModal from '../../../../Layout/Modal/Index'
import mixpanel from 'mixpanel-browser'
import Newsletter from '../../../../Components/Newsletter/Index'
import SocialProof from '../../../../Components/SocialProof/Index'
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index'
import CommerceSlider from '../../../../Components/CommerceSlider/Index'
import WebDesignFAQs from '../../../../Components/FAQs/WebDesignFAQs/Index'
import Process from '../../../../Components/Process/Index'
import WebsiteDesignPricing from '../WebsiteDesignPricing/Index'
import { SiAdobe, SiGoogleanalytics, SiGoogledomains, SiJavascript, SiTarget, SiTestinglibrary, SiGoogle } from '@icons-pack/react-simple-icons'


const WebsiteDesign = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("Website Design Page Visited")
    }, [])

    return (
        <PageLayout>
            <MetaHead
                title="ReactJS Consulting Services - Gurulabs"
                description="Expert ReactJS consulting services to build scalable, high-performance web applications. Get guidance on React architecture, state management, and optimization."
            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-8/12 py-4">
                            <h1 className="text-3xl lg:text-6xl my-1">
                                We specialize in ReactJS Consulting &
                                <span className="text-green-500 relative mx-2">
                                    Website
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                Development
                            </h1>
                            <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Our experienced team of gurus use proven methods to increase your website’s search engine rankings and maximize your online traffic.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full lg:w-4/12 py-4">
                            <img src="/frontend/images/svg/icon-38.svg" className="mx-auto" alt="Website Design By Gurulabs" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <WebsiteDesignPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Simplifying your</span>
                            <span className="text-green-500 ml-2 relative">
                                Design & Development Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Define Objectives and Scope"
                            description="We begin by clearly defining the goals and objectives of the website. We understand your target audience, purpose, and desired features. Outline the scope of the project, including the number of pages, functionalities, and any specific design requirements."
                            button="button-3"
                            Icon={SiTarget}
                        />
                        <Process
                            title="Planning and Research"
                            description="We conduct thorough research on industry trends, competitor websites, and target audience preferences. A comprehensive plan that includes site architecture, navigation structure, and content strategy. It also involves selecting the technology stack."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                        <Process
                            title="Design and Wireframing"
                            description="Develop wireframes and prototypes to visualize the layout and structure of the website. Create a user-friendly and visually appealing design that aligns with the brand identity. This step involves designing the user interface (UI) and user experience (UX), ensuring a seamless and intuitive navigation experience."
                            button="button-3"
                            Icon={SiAdobe}
                        />
                        <Process
                            title="Development"
                            description="Implement the design into a fully functional website during the development phase. This step involves coding, database development, and integrating necessary functionalities. Developers use programming languages such as HTML, CSS, JavaScript, and backend languages (e.g., PHP, NodeJS) to bring the design to life."
                            button="button-2"
                            Icon={SiJavascript}
                        />
                        <Process
                            title="Testing and Quality Assurance"
                            description="Thoroughly test the website to identify and fix any bugs or issues. Perform compatibility testing across various browsers and devices to ensure a consistent user experience. Check the functionality of interactive elements, forms, and other features. Quality assurance is crucial to delivering a reliable and error-free website."
                            button="button-3"
                            Icon={SiTestinglibrary}
                        />
                        <Process
                            title="Launch and Maintenance"
                            description="Once the website has passed testing and quality checks, it's ready for launch. We help Deploy the website to the hosting server, making it accessible to users. After launch, monitor the website's performance, gather user feedback, and address any issues that may arise."
                            button="button-2"
                            Icon={SiGoogledomains}
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">Your Trusted</span>
                                    <span className="text-green-500 ml-2 relative">
                                        ReactJS Consulting Partner
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none">
                                        <path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" />
                                    </svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">
                                At Gurulabs, we help businesses unlock the full potential of React. From architecting clean, scalable codebases to optimizing performance, our consulting services are designed to accelerate your front-end development with confidence.
                            </p>
                        </div>

                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">
                                    Whether you’re starting a new React project or improving an existing one, Gurulabs offers expert ReactJS consulting tailored to your technical challenges. We focus on clean architecture, component design, and long-term scalability to ensure your applications thrive.
                                </p>

                                <p className="text-xl py-4 text-gray-700"><strong>Optimized React Development</strong></p>

                                <p className="text-xl py-4 text-gray-700">
                                    We evaluate your current codebase and provide actionable recommendations to improve performance and maintainability. From reducing unnecessary re-renders to improving state management, we help you create faster, cleaner, and more efficient React apps.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    Our developers bring deep expertise in hooks, functional components, Context API, Redux, and custom architecture patterns. We help you build UI systems that are modular, testable, and easy to evolve as your product grows.
                                </p>

                                <p className="text-xl py-4 text-gray-700"><strong>Comprehensive React Consulting</strong></p>

                                <p className="text-xl py-4 text-gray-700">
                                    Gurulabs isn’t just a React agency—we’re problem solvers. Whether you're building an enterprise dashboard, an eCommerce platform, or a real-time data-driven app, we help you select the right tools and patterns for long-term success.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    From setting up design systems with Storybook to integrating SSR with Next.js, we provide full-spectrum consulting that ensures best-in-class front-end architecture and developer experience.
                                </p>

                                <p className="text-xl py-4 text-gray-700"><strong>Custom Solutions Built on React</strong></p>

                                <p className="text-xl py-4 text-gray-700">
                                    No two React projects are the same. That’s why we tailor our services to your specific tech stack, business logic, and product goals. We assist with everything from setting up CI/CD pipelines to component library development, ensuring your team can move faster and ship with confidence.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    Our collaborative approach means we can work directly with your dev team or take the lead—flexibility that supports both startups and enterprises alike.
                                </p>

                                <p className="text-xl py-4 text-gray-700"><strong>Performance-First Mindset</strong></p>

                                <p className="text-xl py-4 text-gray-700">
                                    Performance is baked into everything we do. We help reduce bundle size, lazy-load components, improve Core Web Vitals, and boost interactivity scores—because speed isn’t just UX, it’s SEO too.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    Using tools like Lighthouse, Webpack Bundle Analyzer, and React Profiler, we continuously fine-tune your app to run at its best across all devices.
                                </p>

                                <p className="text-xl py-4 text-gray-700">
                                    In conclusion, Gurulabs ReactJS Consulting Services deliver the structure, speed, and support your front-end projects need. Whether you're building from the ground up or scaling a production app, we bring clarity and expertise every step of the way.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Newsletter />
            <WebDesignFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CommerceSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default WebsiteDesign
