import React, { Fragment, useEffect, useState } from "react";
import Header from '../Header/Index'
import Footer from '../Footer/Index'
import Banner from '../Banner/Index'
import Navbar from '../Navbar/Index'
import TopBar from '../TopBar/Index'
import CalendarModal from '../CalendarModal/Index'
import { MixpanelConsumer } from "react-mixpanel";
import mixpanel from "mixpanel-browser";
import CookiePolicy from "../../Components/CookiePolicy/Index";
import { useCookies } from 'react-cookie'

function MainLayout({ children }) {
    const [cookie] = useCookies(['cookieConsent'])

    useEffect(() => {

    }, [])

    const [modalIsOpen, setModalIsOpen] = useState(false)
    return (
        <MixpanelConsumer>
            {mixpanel => (
                <>
                    {/* <Banner /> */}
                    <Navbar modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    <TopBar />
                    {children}
                    <Footer />
                    {modalIsOpen && <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />}
                    {cookie.cookieConsent === undefined && <CookiePolicy />}
                </>
            )}
        </MixpanelConsumer>
    )
}

export default MainLayout
