import { Link } from '@inertiajs/inertia-react'
import React from 'react'

const seoPricing = [
    {
        id: 1,
        title: 'Monthly',
        name: 'Monthly $7,995',
        price: '$7,995',
        plans: {
            monthly: '$7,995',
            quarterly: '$23,985',
            annually: '$69,950'
        },  // Monthly and annually the same as only monthly fee mentioned
        href: 'https://calendly.com/gurulabs/15min',
        image: '/frontend/images/svg/icon-15.svg',
        description: 'No minimum commitment. Pause or cancel anytime. Everything necessary to get started.',
        paymentUrl: 'https://calendly.com/gurulabs/15min',
        features: [
            'One request at a time',
            'Unlimited Requests',
            'Unlimited users',
            'Daily standups',
            'Bi-Weekly Status Reports',
        ],
    },
    {
        id: 2,
        title: 'Quarterly',
        name: 'Quarterly $23,985',
        price: '$23,985',
        plans: {
            monthly: '$7,995',
            quarterly: '$23,985',
            annually: '$69,950'
        },
        image: '/frontend/images/svg/icon-16.svg',
        href: 'https://calendly.com/gurulabs/15min',
        description: 'Everything in Monthly, plus essential tools for growing your business.',
        paymentUrl: 'https://buy.stripe.com/aEU17k0lk3pcdAk145',
        features: [
            'One request at a time',
            'Unlimited Requests',
            'Unlimited users',
            'Daily standups',
            'Bi-Weekly Status Reports',
        ]
    },
    {
        id: 3,
        title: 'Yearly',
        name: 'Yearly $69,950',
        price: '$69,950',
        plans: {
            monthly: '$6,995',
            quarterly: '$23,985',
            annually: '$69,950'
        },
        image: '/frontend/images/svg/icon-17.svg',
        description: 'Yearly plan. No minimum commitment. Pause or cancel anytime.',
        paymentUrl: 'https://calendly.com/gurulabs/15min',
        features: [
            'One request at a time',
            'Unlimited Requests',
            'Unlimited users',
            'Daily standups',
            'Bi-Weekly Status Reports',
        ]
    },
];



const AnalyticsPricing = ({ modalIsOpen, setModalIsOpen }) => {
    return (
        <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-20 px-2">
                {
                    seoPricing.map((item, index) => (
                        <div className="border-2 border-gray-100 py-6" key={index}>
                            {item.id === 2 && (
                                <div className="bg-green-300 flex justify-center items-center -mt-10">
                                    <h2 className="py-2 text-gray-700">Most Popular</h2>
                                </div>
                            )}
                            <div className="bg-white py-4 px-2">
                                <img src={item.image} className="py-2 px-2" alt={`${item.title}`} />
                                <h2 className="text-xl py-2">{item.title}</h2>
                                <p className="text-gray-700 py-2">{item.description}</p>
                                <h4 className="text-2xl text-gray-900 py-2">{item.price}</h4>
                                {item.id === 3 ? (
                                    <p className="mt-3 text-sm leading-6 text-gray-700">{item.plans.monthly} per month if paid annually</p>
                                ) : (
                                    <p className="mt-3 text-sm leading-6 text-gray-700">{item.plans.monthly} per month if paid annually</p>
                                )}
                                <ul className="py-4 px-2">
                                    {item.features.map((feature, index) => (

                                        <li className="flex items-center text-gray-700 py-4 px-2 border-b-2" key={index}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.13 12.024a.765.765 0 0 1-.506-.19L4.852 8.505a.766.766 0 0 1 1.013-1.15l3.236 2.845 6.754-6.706a.768.768 0 1 1 1.079 1.094L9.67 11.81a.768.768 0 0 1-.54.215Z" /><path d="M8.661 17.541a8.502 8.502 0 0 1-8.38-9.88A8.5 8.5 0 0 1 11.388.987a.766.766 0 1 1-.492 1.45 6.969 6.969 0 1 0 4.45 8.581.765.765 0 1 1 1.468.438 8.548 8.548 0 0 1-8.152 6.084Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.161.542h17v17h-17z" /></clipPath></defs></svg>
                                            {feature}
                                        </li>

                                    ))}
                                </ul>
                                <div className="flex items-center text-gray-700 py-2 ml-4 px-2">
                                    {/* <a href={item.paymentUrl} className="button w-full py-4 px-4 text-white text-center">Get Started {item.title}</a> */}
                                    {item.id === 3 ? (
                                        <a href={item.paymentUrl}
                                            onClick={() =>
                                                mixpanel.track(`${item.name} Link Clicked`, { 'Plan': item.name, 'Link': item.paymentUrl, 'Page': 'Home Page Pricing', 'User': 'Guest', 'Time': new Date(), 'Location': 'Home Page Pricing' }
                                                )}
                                            className="relative w-full py-3 px-3 top-1 right-2 text-center button rounted-sm text-gray-700 hover:bg-green-500"
                                        >
                                            <span className="text-xl">Schedule a meeting</span>
                                        </a>
                                    ) : (
                                        <a href={item.paymentUrl}
                                            onClick={() => mixpanel.track(`${item.name} Link Clicked`, { 'Plan': item.name, 'Link': item.paymentUrl, 'Page': 'Home Page Pricing', 'User': 'Guest', 'Time': new Date(), 'Location': 'Home Page Pricing' })}
                                            className="relative w-full py-3 px-3 top-1 right-2 text-center button rounted-sm text-gray-700 hover:bg-green-500"
                                        >
                                            <span className="text-xl">Subscribe Now</span>
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default AnalyticsPricing


// import React from 'react';
// import { CheckCircleIcon } from '@heroicons/react/20/solid';

// const tiers1 = [
//     {
//         name: 'Monthly',
//         id: 'tier-monthly',
//         href: '/user/subscription',
//         priceMonthly: '$4,995',
//         description: "No minimum commitment. Pause or cancel anytime.",
//         features: [
//             'One request at a time',
//             'Unlimited Requests',
//             'Unlimited users',
//             'Daily standups',
//             'Bi-Weekly Status Reports',
//         ],
//         featured: false,
//         tier: 'monthly'
//     },
//     {
//         name: 'Yearly',
//         id: 'tier-yearly',
//         href: '/user/subscription',
//         priceMonthly: '$49,995',
//         description: 'No minimum commitment. Pause or cancel anytime. Dedicated support and infrastructure for your company.',
//         features: [
//             'One request at a time',
//             'Unlimited Requests',
//             'Unlimited users',
//             'Daily standups',
//             'Advanced analytics',
//             'Custom integrations',
//             "Marketing Automations",
//             'Bi-Weekly Status Reports',
//             'Dedicated support representative',
//         ],
//         featured: true,
//         tier: 'yearly'
//     },
// ]

// const tiers = [
//     {
//         name: 'Monthly',
//         id: 'tier-monthly',
//         href: 'https://buy.stripe.com/00g7wk4Q76wh0Vi6ou',
//         price: { monthly: '$4995', annually: '$3995' },
//         description: 'Everything necessary to get started.',
//         features: [
//             'One request at a time',
//             'Unlimited Requests',
//             'Unlimited users',
//             'Daily standups',
//             'Bi-Weekly Status Reports',],
//         tier: 'monthly'
//     },
//     {
//         name: 'Quarterly',
//         id: 'tier-Quarterly',
//         href: 'https://buy.stripe.com/eVaaIw96ndYJcE0005',
//         price: { monthly: '$14,985', annually: '$3995' },
//         description: 'Everything in Monthly, plus essential tools for growing your business.',
//         features: [
//             'One request at a time',
//             'Unlimited Requests',
//             'Unlimited users',
//             'Daily standups',
//             'Bi-Weekly Status Reports',
//         ],
//         tier: 'quarterly'
//     },
//     {
//         name: 'Yearly',
//         id: 'tier-growth',
//         href: 'https://buy.stripe.com/dR68Ao82j6whavS4gn',
//         price: { monthly: '$47,940', annually: '$3995' },
//         description: 'Everything in Monthly, plus collaboration tools and deeper insights.',
//         features: [
//             'One request at a time',
//             'Unlimited Requests',
//             'Unlimited users',
//             'Daily standups',
//             'Advanced analytics',
//             'Custom integrations',
//             "Marketing Automations",
//             'Bi-Weekly Status Reports',
//             'Dedicated support representative',
//         ],
//         tier: 'yearly'
//     },
// ]

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

// function Pricing() {
//     return (
//         <>
//             <div className="bg-white py-24 sm:py-32">
//                 <div className="mx-auto max-w-7xl px-6 lg:px-8">
//                     <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
//                         <h3 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
//                             Choose a plan that's right
//                             <span className="text-amber-950 ml-2 relative">
//                                 for you.
//                                 <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-4 ml-0 hidden xl:block" width={500} height={3} fill="none"><path stroke="#FFAC36" strokeWidth={2} d="M0 1.85h150.5" /></svg>
//                             </span>
//                         </h3>
//                     </div>
//                     <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
//                         Perks so good you'll never need to go anywhere else for your All in One solution. Seriously.
//                     </p>
//                     <div className="mt-20 flow-root">
//                         <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
//                             {tiers.map((tier) => (
//                                 <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
//                                     <h3 id={tier.id} className="text-base font-semibold leading-7 text-gray-900">
//                                         {tier.name}
//                                     </h3>
//                                     <p className="mt-6 flex items-baseline gap-x-1">
//                                         <span className="text-5xl font-bold tracking-tight text-gray-900">{tier.price.monthly}</span>
//                                         <span className="text-sm font-semibold leading-6 text-gray-600">/{tier.tier}</span>
//                                     </p>
//                                     <p className="mt-3 text-sm leading-6 text-gray-700">{tier.price.annually} per month if paid annually</p>
//                                     <a
//                                         href={tier.href}
//                                         aria-describedby={tier.id}
//                                         className="mt-10 block rounded-md button px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
//                                     >
//                                         Buy plan
//                                     </a>
//                                     <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{tier.description}</p>
//                                     <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
//                                         {tier.features.map((feature) => (
//                                             <li key={feature} className="flex gap-x-3">
//                                                 <CheckCircleIcon className="h-6 w-5 flex-none text-green-600" aria-hidden="true" />
//                                                 {feature}
//                                             </li>
//                                         ))}
//                                     </ul>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default Pricing
