import React from 'react';

const SuccessStory = ({ setModalIsOpen }) => {
    return (
        <section className="bg-amber-50 py-12 px-6">
            <div className="max-w-7xl mx-auto space-y-12">
                <div className="text-center">
                    <h1 className="text-4xl font-bold text-gray-900">Our Success Stories</h1>
                    <p className="mt-4 text-gray-700 text-lg">
                        Discover how we help businesses grow through tailored automation, web solutions, and data-driven strategies.
                    </p>
                </div>
                <div className="grid grid-cols-1">
                    {/* Case Study 1 */}
                    <div className="bg-white shadow-md rounded-lg m-2 p-6">
                        <h2 className="text-2xl font-semibold text-gray-900">
                            Speakerlabs: Driving Sales Growth with Automation & Analytics
                        </h2>
                        <p className="text-gray-600 mt-2">
                            Learn how Speakerlabs increased their sales by 30% and streamlined lead generation using advanced tracking and automation tools.
                        </p>
                        <hr className="my-4 border-gray-200" />
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Challenges</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>Difficulty in tracking leads and attributing campaigns.</li>
                                    <li>Manual workflows leading to inefficiency.</li>
                                    <li>Disparate systems and inconsistent data reporting.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Solution</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>Implemented advanced tracking via Google Tag Manager and server-side GTM.</li>
                                    <li>Set up UTM tracking for lead attribution.</li>
                                    <li>Automated workflows with Zapier and integrated Looker Studio for analytics.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Results</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>30% increase in sales through effective tracking and automation.</li>
                                    <li>Improved lead generation efficiency and quality.</li>
                                    <li>Streamlined processes with reduced manual effort by 40%.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-6">
                            <button

                                onClick={() => setModalIsOpen(true)}
                                className="button text-white px-6 py-2 rounded-lg font-medium shadow hover:bg-green-700"
                            >
                                Schedule a meeting
                            </button>
                        </div>
                    </div>
                    {/* Case Study 2 */}
                    <div className="bg-white shadow-md rounded-lg m-2 p-6">
                        <h2 className="text-2xl font-semibold text-gray-900">
                            Renae Mussachio: Streamlining Business Operations
                        </h2>
                        <p className="text-gray-600 mt-2">
                            Discover how Renae enhanced her online presence and automated workflows for growth and scalability.
                        </p>
                        <hr className="my-4 border-gray-200" />
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Challenges</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>Fragmented website design with limited functionality.</li>
                                    <li>Manual customer follow-ups and email campaigns.</li>
                                    <li>Lack of integration across marketing tools.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Solution</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>Built a responsive, custom WordPress website.</li>
                                    <li>Integrated MailChimp and ActiveCampaign for email marketing.</li>
                                    <li>Automated workflows using Zapier and Google AppScripts.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Results</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>Increased website engagement and conversions.</li>
                                    <li>Reduced manual effort by 70% through automation.</li>
                                    <li>Scalable system designed for future growth.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-6">
                            <button

                                onClick={() => setModalIsOpen(true)}
                                className="button text-white px-6 py-2 rounded-lg font-medium shadow hover:bg-green-700"
                            >
                                Schedule a meeting
                            </button>
                        </div>
                    </div>
                    {/* Case Study 3 */}
                    <div className="bg-white shadow-md rounded-lg m-2 p-6">
                        <h2 className="text-2xl font-semibold text-gray-900">
                            Deliver ME Trivia: Automating Operations for Growth
                        </h2>
                        <p className="text-gray-600 mt-2">
                            See how Deliver ME Trivia streamlined their request management and enhanced lead analysis with Gurulabs’ custom automation solutions.
                        </p>
                        <hr className="my-4 border-gray-200" />
                        <div className="space-y-4">
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Challenges</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>Manual processing of founder requests, emails, and pitch decks.</li>
                                    <li>Lack of centralized systems to track and prioritize incoming opportunities.</li>
                                    <li>Difficulty extracting actionable insights from large volumes of data.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Solution</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>Implemented Airtable to centralize and manage founder requests.</li>
                                    <li>Automated email parsing and data flow using Zapier.</li>
                                    <li>Integrated AI-powered tools for analyzing emails and pitch decks.</li>
                                    <li>Enhanced tracking with Google AppScripts and API integrations.</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-xl font-semibold text-gray-800">Results</h3>
                                <ul className="list-disc list-inside text-gray-600 mt-2 space-y-2">
                                    <li>Reduced manual data processing time by over 60%.</li>
                                    <li>Improved lead analysis and decision-making with AI insights.</li>
                                    <li>Enabled scalability to handle increasing volumes of founder requests.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="mt-6">
                            <button
                                onClick={() => setModalIsOpen(true)}
                                className="button text-white px-6 py-2 rounded-lg font-medium shadow hover:bg-green-700"
                            >
                                Schedule a meeting
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SuccessStory;
