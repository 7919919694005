import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import mixpanel from 'mixpanel-browser'
import Process from '../../../../Components/Process/Index'
import { SiBiolink, SiFacebook, SiFiles, SiGoogle, SiGoogleanalytics, SiGooglesearchconsole, SiHtml5, SiInstagram, SiLinkedin, SiTiktok, SiTwitter, SiYoutube } from '@icons-pack/react-simple-icons'
import { LinkIcon } from '@heroicons/react/24/outline'
import SearchEngineSlider from '../../../../Components/SearchEngineSlider/Index'
import Slider from '../../../../Components/Slider/Index'
import CalendarModal from '../../../../Layout/CalendarModal/Index'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import LocalSEOPricing from '../../../../Components/SEOPricing/Local/Index'
import SEOFAQs from '../../../../Components/FAQs/SEOFAQs/Index'
import Newsletter from '../../../../Components/Newsletter/Index'
import LocalSEOPricingTable from '../../../../Components/PricingTables/SEOPricingTable/Local/Index'
import Popup from '../../../../Components/Popup/Index'
import SEOMetaHead from '../../../../Components/SEOMetaHead/Index'
import SocialProof from '../../../../Components/SocialProof/Index'

const SEOLocalPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("SEO Service Page Visited")
    }, [])

    return (
        <PageLayout>
            <SEOMetaHead
                title="Local SEO Services - Targeted Local Visibility in 2024"
                description="Enhance your local SEO with Gurulabs' specialized services. Increase your local search visibility by 30% and dominate Google's local rankings. Reach out to Gurulabs for tailored local SEO strategies."

            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full py-4 xl:w-8/12">
                            <h1 className="text-3xl lg:text-6xl my-1">Boost your
                                <span className="text-green-500 relative mx-2">
                                    local visibility
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                using our Search Engine Optimization Strategies</h1>
                            <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Our experienced team of gurus use proven methods to increase your website’s search engine rankings and maximize your online traffic.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full py-4 xl:w-4/12">
                            <img src="/frontend/images/svg/icon-25.svg" className="mx-auto" alt="Local SEO By Gurulabs" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what local seo services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <LocalSEOPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                Loacl Search Engine Optimization
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="SEO Audit"
                            description="Starting with a comprehensive SEO audit, Gurulabs evaluates your current website to identify strengths, weaknesses, and opportunities for optimization. Our detailed assessment covers technical SEO, content, and backlink profiles to establish a baseline and craft a strategy that targets your specific business objectives and market position."
                            button="button-3"
                            Icon={SiGooglesearchconsole}
                        />
                        <Process
                            title="Keyword Research and Strategy"
                            description="Gurulabs performs extensive keyword research to understand the search landscape within your industry. We identify high-value keywords based on relevance, search volume, and competition level, forming a strategic foundation that drives content creation and optimization efforts to attract and engage your target audience effectively."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                        <Process
                            title="On-page SEO Optimization"
                            description="Our team meticulously optimizes on-page elements, including title tags, meta descriptions, headers, and images, ensuring they align with targeted SEO strategies. Gurulabs enhances your site’s usability and relevance, improving your search engine rankings while delivering a seamless user experience."
                            button="button-3"
                            Icon={SiGoogle}
                        />
                        <Process
                            title="Content Development"
                            description="Content is king in SEO, and Gurulabs specializes in creating SEO-driven content that not only ranks but resonates with your audience. From blog posts to whitepapers, we produce high-quality, engaging content tailored to your brand’s voice and user intent, positioning your business as a thought leader in your industry."
                            button="button-2"
                            Icon={SiHtml5}
                        />
                        <Process
                            title="Link Building Strategy"
                            description="Gurulabs uses a white-hat approach to link building, securing high-quality backlinks from authoritative websites to boost your site's authority and search engine rankings. Our strategies include guest blogging, influencer collaborations, and strategic partnerships, enhancing your online presence and driving organic traffic."
                            button="button-3"
                            Icon={LinkIcon}
                        />
                        <Process
                            title="Performance Tracking and Reporting"
                            description="With Gurulabs, transparency is paramount. We track the progress of your SEO campaign using advanced analytics tools to monitor key performance indicators like traffic, rankings, and conversions. Regular reports keep you informed of your SEO investment's impact, enabling continuous improvement and strategy refinement."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />

                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're an affordable</span>
                                    <span className="text-green-500 ml-2 relative">
                                        Local Search Engine Optimization
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">Our SEO management process helps you identify your target audience, optimize your website and content strategies, and gather valuable insights to increase organic traffic and maximize your return on investment.</p>

                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we understand the power of localization in SEO and are dedicated to boosting your brand's visibility and driving traffic through targeted <a href="/blog/local-search-engine-optimization-seo" className="font-bold under">local search engine optimization </a> strategies. Our local SEO services are tailored to ensure that your business stands out in its local market, attracting relevant traffic and fostering growth.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Local SEO Strategy Development</strong></p>

                                <p className="text-xl py-4 text-gray-700">Our process begins with a thorough understanding of your local market and business objectives. Gurulabs conducts in-depth market analysis and competitor benchmarking to identify opportunities for local dominance. We customize your SEO strategy to focus on geo-specific keywords, local listings, and map visibility, ensuring your business connects directly with the community it serves.</p>

                                <p className="text-xl py-4 text-gray-700">We specialize in optimizing Google My Business profiles, enhancing local citations, and crafting location-based content that resonates with your local audience. By tailoring our approach to the nuances of your locality, Gurulabs ensures that your business is not only seen but also preferred by local customers.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Expertise in Local Search Dynamics</strong></p>

                                <p className="text-xl py-4 text-gray-700">Gurulabs' expertise extends beyond basic SEO; we are proficient in the dynamics of local search algorithms and understand how to leverage them to your advantage. From managing online reviews to engaging with local audiences through targeted content, our strategies are designed to improve your local search rankings and online reputation.</p>

                                <p className="text-xl py-4 text-gray-700">Our comprehensive local SEO management services ensure that every aspect of your digital presence is optimized for local search success. From detailed keyword research to the development of engaging, locally relevant content and authoritative local link building, we cover all bases to elevate your local search presence.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Customized Solutions for Local Businesses</strong></p>

                                <p className="text-xl py-4 text-gray-700">Every local market is unique, and so is your business. Gurulabs offers customized local SEO solutions designed to meet the specific needs of your industry and target demographic. Whether you're looking to increase foot traffic, boost local online sales, or improve local brand awareness, Gurulabs has the expertise to support your goals.</p>

                                <p className="text-xl py-4 text-gray-700">We collaborate closely with you to understand your business objectives and craft a strategy that delivers real local results. Our local SEO services not only aim to increase your visibility but also to connect you with your ideal customers who are ready to engage.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Maximizing Your Local Search Engine Visibility and ROI</strong></p>

                                <p className="text-xl py-4 text-gray-700">Success in local SEO is measured by tangible results. At Gurulabs, our focus is on maximizing your visibility in local search engines and ensuring a high return on investment. We employ a mix of proven local strategies and innovative techniques to make your business not only visible but also predominant in its community.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, choosing Gurulabs for local search engine optimization service means opting for a full suite of services that simplify and enhance your local online presence. From detailed local market analysis to ongoing optimization, we provide comprehensive local SEO solutions that deliver lasting results. Partner with Gurulabs today and propel your business to the top of local search engine rankings.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <LocalSEOPricingTable />
            <Newsletter />
            <SEOFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <SearchEngineSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
            {/* <Popup /> */}
        </PageLayout>
    )
}
export default SEOLocalPage
