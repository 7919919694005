import { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

const IntercomLauncher = () => {
  useEffect(() => {
    // Initialize Intercom
    Intercom({
      app_id: 'pfzcfnm3', // Replace with your actual app_id
    });

    // Function to handle scroll event
    const handleScroll = () => {
      console.log("User scrolled, launching Intercom Messenger...");
      Intercom('show'); // Show the Messenger
      window.removeEventListener('scroll', handleScroll); // Remove listener after first activation
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return null; // This component does not render anything
};

export default IntercomLauncher;
