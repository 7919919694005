import React, { Fragment } from 'react'
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { Link } from '@inertiajs/inertia-react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { usePage } from '@inertiajs/inertia-react'
import Swal from 'sweetalert2'
import {
    ArrowPathIcon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    DocumentChartBarIcon,
    FingerPrintIcon,
    MapPinIcon,
    SquaresPlusIcon,
} from '@heroicons/react/24/outline'
import { SiAbbvie, SiAffinitydesigner, SiBuffer, SiBugatti, SiChase, SiElasticstack, SiFacebook, SiFigma, SiGeneralmotors, SiGoogleads, SiGoogleanalytics, SiGooglemaps, SiGooglemybusiness, SiGoogleoptimize, SiGoogletagmanager, SiHtml5, SiInstagram, SiIntegromat, SiJavascript, SiLaravel, SiLeaflet, SiLooker, SiMixcloud, SiPagespeedinsights, SiPowerbi, SiPython, SiQuicklook, SiReact, SiRemix, SiRubyonrails, SiSamsung, SiSemanticuireact, SiShopify, SiSiemens, SiSnowflake, SiSupabase, SiTableau, SiTiktok, SiWipro, SiYelp, SiYoutube, SiZapier, SiZoho } from '@icons-pack/react-simple-icons'
import {
    SiSolidity, // Solar
    SiMediatek, // Healthcare
    SiFitbit, // Fitness
    SiFifa, // Sports
    SiHomeadvisor, // Real Estate
    SiGooglescholar, // Education
    SiApache, // Non-Profit
    SiUber, // Restaurants
    SiTesla, // Technology
    SiCashapp, // Finance
    SiCmake, // Construction
    SiDatabricks, // Automotive
    SiYourtraveldottv, // Travel
    SiStylelint, // Fashion
    SiAdobe, // Beauty
    SiAudiomack, // Entertainment & Music
    SiArtstation, // Art
    SiFlickr, // Photography
    SiHandshake, // Law Firms
} from '@icons-pack/react-simple-icons';

// const solutions = [
//     { name: 'SEO Services', href: '/seo/services' },
//     { name: 'Lead Generation', href: '/lead-generation' },
//     { name: 'SEO Local', href: '/seo/local' },
//     { name: 'SEO National', href: '/seo/national' },
//     { name: 'SEO Enterprise', href: '/seo/enterprise' },
// ]

const leadgen = [
    {
        name: 'SEO Services',
        description: 'Optimize your website to rank higher in search engine results and enhance your online visibility.',
        href: '/seo/services',
        icon: ChartPieIcon // Suitable for showing data-driven strategy
    },
    {
        name: 'Lead Generation',
        description: 'Generate high-quality leads with targeted campaigns and integrate seamlessly with your CRM tools.',
        href: '/lead-generation',
        icon: SquaresPlusIcon, // Indicates adding (generating) new elements (leads)
    },
    {
        name: 'SEO Local',
        description: 'Boost your local search rankings and connect with your local customers more effectively.',
        href: '/seo/local',
        icon: CursorArrowRaysIcon, // Good for representing outreach or expansion in local area
    },
    {
        name: 'SEO National',
        description: 'Expand your reach across the nation with tailored SEO strategies that drive conversions.',
        href: '/seo/national',
        icon: ArrowPathIcon // Represents strategic direction and path-making
    },
    {
        name: 'SEO Enterprise',
        description: 'Secure and scale your large-scale SEO efforts with our enterprise-grade solutions.',
        href: '/seo/enterprise',
        icon: FingerPrintIcon // Emphasizes security and personalization
    },
    {
        name: 'Conversion Rate Optimization',
        description: 'Optimize your website’s conversion rates with strategic adjustments and A/B testing to maximize your ROI.',
        href: '/seo/conversion-rate-optimization',
        icon: SiGoogleoptimize // Emphasizes security and personalization
    },
    {
        name: 'Citation Building',
        description: 'Manage and enhance your online business presence with accurate citations across directories.',
        href: '/seo/citations-builder',
        icon: SiYelp // Represents strategic direction and path-making
    },
    {
        name: 'On-Page Optimization',
        description: 'Enhance your website’s visibility and performance with targeted on-page SEO strategies.',
        href: '/seo/on-page-optimization',
        icon: SiPagespeedinsights // Represents optimization and performance improvements
    },
    {
        name: 'GMB Optimization',
        description: 'Boost your business’s visibility in local search results with tailored Google My Business optimization strategies.',
        href: '/seo/gmb-optimization',
        icon: SiGooglemybusiness // Represents local business visibility and optimization
    },
    {
        name: 'Social Media Managemement',
        description: 'Optimize your website’s conversion rates with strategic adjustments and A/B testing to maximize your ROI.',
        href: '/social/social-media-management',
        icon: SiBuffer // Emphasizes security and personalization
    },
]


const paidAds = [
    {
        name: 'Paid Ads',
        description: 'Optimize your website to rank higher in search engine results and enhance your online visibility.',
        href: '/paid-ads',
        icon: SiGoogletagmanager // Suitable for showing data-driven strategy
    },
    {
        name: 'Enhance Conversion Tracking',
        description: 'Enhance your website’s conversion rates with strategic adjustments and A/B testing to maximize your ROI.',
        href: '/ppc/enhance-conversion-tracking',
        icon: SiGoogleanalytics // Suitable for showing data-driven strategy
    },
    {
        name: 'Google Ads',
        description: 'Generate high-quality leads with targeted campaigns and integrate seamlessly with your CRM tools.',
        href: '/ppc/google-ads',
        icon: SiGoogleads, // Indicates adding (generating) new elements (leads)
    },
    {
        name: 'Facebook Ads',
        description: 'Boost your local search rankings and connect with your local customers more effectively.',
        href: '/ppc/facebook-ads',
        icon: SiFacebook, // Good for representing outreach or expansion in local area
    },
    {
        name: 'Tiktok Ads',
        description: 'Expand your reach across the nation with tailored SEO strategies that drive conversions.',
        href: '/ppc/tiktok-ads',
        icon: SiTiktok // Represents strategic direction and path-making
    },
    {
        name: 'Shopify Ads',
        description: 'Secure and scale your large-scale SEO efforts with our enterprise-grade solutions.',
        href: '/ppc/shopify-ads',
        icon: SiShopify // Emphasizes security and personalization
    },
    {
        name: 'Youtube Ads',
        description: 'Optimize your website’s conversion rates with strategic adjustments and A/B testing to maximize your ROI.',
        href: '/ppc/youtube-ads',
        icon: SiYoutube // Emphasizes security and personalization
    },
    {
        name: 'Instagram Ads',
        description: 'Optimize your website’s conversion rates with strategic adjustments and A/B testing to maximize your ROI.',
        href: '/ppc/instagram-ads',
        icon: SiInstagram // Emphasizes security and personalization
    },
]


const analytics = [
    {
        name: 'Segment.io Consulting',
        description: 'Gain comprehensive insights into user behavior and optimize your marketing strategies with Segment.io.',
        href: '/analytics/segment-io-consulting',
        icon: SiSemanticuireact // Suitable for showing data-driven strategy
    },
    {
        name: 'Mixpanel Consulting',
        description: 'Utilize Mixpanel to track user interactions and improve your product based on real-time analytics.',
        href: '/analytics/mixpanel-consulting',
        icon: SiMixcloud // Indicates data tracking and analysis
    },
    {
        name: 'Google Analytics Consulting',
        description: 'Leverage Google Analytics to understand your audience and make informed business decisions.',
        href: '/analytics/google-analytics-consulting',
        icon: SiGoogleanalytics // Good for representing comprehensive analytics
    },
    // {
    //     name: 'Tableau Consulting',
    //     description: 'Visualize and analyze your data with Tableau to uncover actionable insights and drive business growth.',
    //     href: '/analytics/google-analytics-consulting',
    //     icon: SiTableau // Represents data visualization and analysis
    // },
    // {
    //     name: 'Power BI Consulting',
    //     description: 'Empower your team with data-driven insights using Microsoft Power BI for advanced analytics and reporting.',
    //     href: '/analytics/google-tag-manager-consulting',
    //     icon: SiPowerbi // Represents advanced analytics and reporting
    // },
    {
        name: 'Google Tag Manager Consulting',
        description: 'Streamline your tag management process and gain accurate data with Google Tag Manager.',
        href: '/analytics/google-tag-manager-consulting',
        icon: SiGoogletagmanager // Emphasizes tag management and accuracy
    },
    {
        name: 'Looker Studio Consulting',
        description: 'Transform your data into actionable insights with Looker and improve your business performance.',
        href: '/analytics/looker-studio-consulting',
        icon: SiLooker // Represents data transformation and insights
    },
    {
        name: 'Elastic Stack (ELK) Consulting',
        description: 'Enhance your data management and analysis capabilities with Elastic Stack (ELK) consulting services.',
        href: '/analytics/elastic-stack-consulting',
        icon: SiElasticstack // Represents cloud data management and analysis
    },
]


// const shopping = [
//     { name: 'E-Commerce', href: '/e-commerce' },
//     { name: 'Woo-Commerce', href: '/e-commerce' },
//     { name: 'Shopify', href: '/e-commerce' },
//     { name: 'Amazon Marketplace', href: '/e-commerce' },
//     { name: 'Facebook Marketplace', href: '/e-commerce' },
//     { name: 'Google Merchant Center', href: '/e-commerce' },
//     { name: 'Target Plus Marketplace', href: '/e-commerce' },
//     { name: 'Walmart Marketplace', href: '/e-commerce' },
// ]

const shopping = [
    {
        name: 'E-Commerce',
        description: 'Gain comprehensive insights into user behavior and optimize your marketing strategies with Segment.io.',
        href: '/e-commerce',
        icon: SiSemanticuireact // Suitable for showing data-driven strategy
    },
    {
        name: 'Shopify Consulting',
        description: 'Utilize Mixpanel to track user interactions and improve your product based on real-time analytics.',
        href: '/shop/shopify-consulting',
        icon: SiMixcloud // Indicates data tracking and analysis
    },
    {
        name: 'Woo-Commerce Consulting',
        description: 'Leverage Google Analytics to understand your audience and make informed business decisions.',
        href: '/shop/woocommerce-consulting',
        icon: SiGoogleanalytics // Good for representing comprehensive analytics
    },
]

const automation = [
    {
        name: 'Zapier Consulting',
        description: 'Utilize Zapier to Automate your workflows and connect your apps and services together.',
        href: '/automation/zapier-consulting',
        icon: SiZapier // Indicates data tracking and analysis
    },
    {
        name: 'Make Consulting',
        description: 'Leverage Make.com to Automate your workflows and connect your apps and services together.',
        href: '/automation/make-consulting',
        icon: SiIntegromat // Good for representing comprehensive analytics
    },
    {
        name: 'Pabbly Consulting',
        description: 'Pabbly is a powerful automation tool that can help you automate your business processes.',
        href: '/automation/pabbly-consulting',
        icon: SiSupabase // Good for representing comprehensive analytics
    },
]

const development = [
    {
        name: 'Web Design',
        description: 'We craft visually appealing and highly functional web designs that capture your brand\'s essence.',
        href: '/web-design',
        icon: SiFigma // Suitable for showing data-driven strategy
    },
    {
        name: 'Web Development',
        description: 'Gurulabs leverages the latest technologies, best practices to ensure your project is a success.',
        href: '/web-design/website-development',
        icon: SiHtml5, // Indicates adding (generating) new elements (leads)
    },
    {
        name: 'JavaScript Consulting',
        description: 'Gurulabs Experts in JavaScript development and consulting services.',
        href: '/web-design/javascript-consulting',
        icon: SiJavascript, // Good for representing outreach or expansion in local area
    },
    {
        name: 'ReactJS Consulting',
        description: 'Our gurus brings extensive experience in ReactJS solutions tailored to meet your needs. ',
        href: '/web-design/reactjs-consulting',
        icon: SiReact // Represents strategic direction and path-making
    },
    {
        name: 'Laravel Consulting',
        description: 'Our Laravel Consulting service is designed to provide robust, secure, and scalable solutions',
        href: '/web-design/laravel-consulting',
        icon: SiLaravel // Emphasizes security and personalization
    },
    {
        name: 'Ruby on Rails Consulting',
        description: 'Leverage our Ruby on Rails Consulting to create robust, efficient, and maintainable code.',
        href: '/web-design/ruby-on-rails-consulting',
        icon: SiRubyonrails // Emphasizes security and personalization
    },
]


const ppcIndustries = [
    { name: 'Solar', href: '/ppc/solar', icon: SiSolidity, description: 'Maximize visibility for solar energy businesses with tailored PPC campaigns.' },
    { name: 'Real Estate', href: '/ppc/real-estate', icon: SiHomeadvisor, description: 'Generate high-quality leads for real estate firms with targeted advertising.' },
    { name: 'Healthcare', href: '/ppc/healthcare', icon: SiMediatek, description: 'Drive patient engagement and increase online presence for healthcare services.' },
    { name: 'Law Firms', href: '/ppc/legal', icon: SiHandshake, description: 'Attract potential clients through high-performing PPC strategies for law firms.' },
    { name: 'E-Commerce', href: '/ppc/e-commerce', icon: SiShopify, description: 'Optimize online sales and brand visibility for e-commerce businesses.' },
    { name: 'Education', href: '/ppc/education', icon: SiGooglescholar, description: 'Boost enrollment rates and brand awareness for educational institutions.' },
    { name: 'Non-Profit', href: '/ppc/non-profit', icon: SiApache, description: 'Increase donor engagement and online reach for non-profits with PPC ads.' },
    { name: 'Restaurants', href: '/ppc/restaurants', icon: SiUber, description: 'Drive more reservations and food orders with strategic paid advertising.' },
    { name: 'Technology', href: '/ppc/technology', icon: SiTesla, description: 'Expand brand recognition and lead generation in the tech industry.' },
    { name: 'Finance', href: '/ppc/finance', icon: SiCashapp, description: 'Increase conversions for financial services and fintech companies.' },
    { name: 'Construction', href: '/ppc/construction', icon: SiCmake, description: 'Boost brand awareness and lead generation for construction companies.' },
    { name: 'Automotive', href: '/ppc/automotive', icon: SiDatabricks, description: 'Enhance dealership visibility and drive auto sales through PPC campaigns.' },
    { name: 'Travel', href: '/ppc/travel', icon: SiYourtraveldottv, description: 'Increase bookings and travel package visibility with optimized PPC ads.' },
    { name: 'Fashion', href: '/ppc/fashion', icon: SiStylelint, description: 'Promote clothing brands and fashion retailers with high-impact ads.' },
    { name: 'Beauty', href: '/ppc/beauty', icon: SiAdobe, description: 'Grow brand awareness and online sales for beauty and skincare companies.' },
    { name: 'Fitness', href: '/ppc/fitness', icon: SiFitbit, description: 'Boost gym memberships and health product sales with precise ad targeting.' },
    { name: 'Entertainment', href: '/ppc/entertainment', icon: SiAudiomack, description: 'Engage audiences and promote media content through PPC advertising.' },
    { name: 'Sports', href: '/ppc/sports', icon: SiFifa, description: 'Enhance brand reach for sports organizations and athletic brands through paid ads.' },
    { name: 'Music', href: '/ppc/music', icon: SiAudiomack, description: 'Maximize exposure for artists, labels, and music streaming services.' },
    { name: 'Photography', href: '/ppc/photography', icon: SiFlickr, description: 'Increase exposure and bookings for photographers with paid advertising.' },
    { name: 'Manufacturing', href: '/ppc/manufacturing', icon: SiGeneralmotors, description: 'Drive B2B leads, enhance product visibility, and boost growth for manufacturing companies through precision PPC strategies.' },
    {
        name: 'HVAC',
        href: '/ppc/hvac',
        icon: SiSiemens,
        description: 'Generate qualified leads and grow visibility for HVAC service providers through highly targeted PPC campaigns.'
    },
    {
        name: 'Plumbing',
        href: '/ppc/plumbing',
        icon: SiZoho,
        description: 'Attract more plumbing service calls with locally targeted PPC advertising strategies.'
    },
    {
        name: 'Electrical',
        href: '/ppc/electrical',
        icon: SiAbbvie,
        description: 'Increase visibility and bookings for residential and commercial electrical services.'
    },
    {
        name: 'Roofing',
        href: '/ppc/roofing',
        icon: SiHomeadvisor,
        description: 'Drive high-quality leads and grow your roofing business with proven PPC strategies.'
    },
    {
        name: 'Landscaping',
        href: '/ppc/landscaping',
        icon: SiLeaflet,
        description: 'Grow local reach and generate more landscaping and lawn care service leads with paid ads.'
    },
    {
        name: 'Pest Control',
        href: '/ppc/pest-control',
        icon: SiBugatti,
        description: 'Eliminate competition with smart PPC campaigns for pest control services.'
    },
    {
        name: 'Painting',
        href: '/ppc/painting',
        icon: SiAffinitydesigner,
        description: 'Boost leads for residential and commercial painting projects using precision PPC targeting.'
    },
    {
        name: 'Carpentry',
        href: '/ppc/carpentry',
        icon: SiRemix,
        description: 'Drive visibility and attract premium carpentry and woodworking projects with online advertising.'
    },
    {
        name: 'Welding',
        href: '/ppc/welding',
        icon: SiWipro,
        description: 'Increase custom and industrial welding job leads through high-performing PPC strategies.'
    },
    {
        name: 'Flooring',
        href: '/ppc/flooring',
        icon: SiQuicklook,
        description: 'Get more flooring installation and renovation leads with hyper-local PPC ads.'
    },
    {
        name: 'Appliance Repair',
        href: '/ppc/appliance-repair',
        icon: SiSamsung,
        description: 'Capture service-ready customers for appliance repair with targeted PPC campaigns.'
    },
    {
        name: 'Garage Doors',
        href: '/ppc/garage-doors',
        icon: SiChase,
        description: 'Enhance lead generation for garage door repair and installation businesses through PPC.'
    }
];





function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function MenuLinks() {
    const { url, component } = usePage()
    return (
        <>

            <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Marketing Solutions</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-2xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-4xl">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-6 p-6">
                                {/* SEO Services */}
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-900 mb-2">Lead Generation</h3>
                                    <div className="space-y-3">
                                        {leadgen.slice(0, 10).map((item) => (
                                            <div
                                                key={item.name}
                                                className="group relative flex items-start gap-x-4 hover:bg-gray-50 p-2 rounded-lg"
                                            >
                                                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" />
                                                </div>
                                                <div>
                                                    <Link href={item.href} className="text-sm font-medium text-gray-900">
                                                        {item.name}

                                                        <p className="text-xs text-gray-600">{item.description}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Analytics Services */}
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-900 mb-2">Analytics Services</h3>
                                    <div className="space-y-3">
                                        {analytics.map((item) => (
                                            <div
                                                key={item.name}
                                                className="group relative flex items-start gap-x-4 hover:bg-gray-50 p-2 rounded-lg"
                                            >
                                                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" />
                                                </div>
                                                <div>
                                                    <Link href={item.href} className="text-sm font-medium text-gray-900">
                                                        {item.name}

                                                        <p className="text-xs text-gray-600">{item.description}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-6 py-4">
                                <p className="text-xs text-gray-500">
                                    Explore our SEO and analytics services to achieve measurable success.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>


            {/* Paid Ads */}
            <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Paid Ads</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-2xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-4xl">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-6 p-6">
                                {paidAds.map((item) => (
                                    <div
                                        key={item.name}
                                        className="group relative flex items-start gap-x-4 hover:bg-gray-50 p-2 rounded-lg"
                                    >
                                        <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" />
                                        </div>
                                        <div>
                                            <Link href={item.href} className="text-sm font-medium text-gray-900">
                                                {item.name}

                                                <p className="text-xs text-gray-600">{item.description}</p>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="bg-gray-50 px-6 py-4">
                                <p className="text-xs text-gray-500">
                                    Optimize your campaigns and maximize ROI with our expert Paid Ads management services.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>



            <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Technology & Automation</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-2xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-4xl">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-6 p-6">
                                {/* Automation Services */}
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-900 mb-2">Automation Services</h3>
                                    <div className="space-y-3">
                                        {automation.map((item) => (
                                            <div key={item.name} className="group relative flex items-start gap-x-4 hover:bg-gray-50 p-2 rounded-lg">
                                                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" />
                                                </div>
                                                <div>
                                                    <Link href={item.href} className="text-sm font-medium text-gray-900">
                                                        {item.name}

                                                        <p className="text-xs text-gray-600">{item.description}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                {/* Web Development */}
                                <div>
                                    <h3 className="text-sm font-semibold text-gray-900 mb-2">Web Development</h3>
                                    <div className="space-y-3">
                                        {development.map((item) => (
                                            <div key={item.name} className="group relative flex items-start gap-x-4 hover:bg-gray-50 p-2 rounded-lg">
                                                <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" />
                                                </div>
                                                <div>
                                                    <Link href={item.href} className="text-sm font-medium text-gray-900">
                                                        {item.name}

                                                        <p className="text-xs text-gray-600">{item.description}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-6 py-4">
                                <p className="text-xs text-gray-500">
                                    Automate your workflows and build robust web solutions with our expert services.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>

            <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Industries</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-3xl flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-4xl">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 p-6">
                                {ppcIndustries.map((industry) => (
                                    <div key={industry.name} className="group relative flex items-start gap-x-4 hover:bg-gray-50 p-2 rounded-lg">
                                        <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            {industry.icon && <industry.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" />}
                                        </div>
                                        <div>
                                            <Link href={industry.href} className="text-sm font-medium text-gray-900 block">
                                                {industry.name}
                                                <p className="text-xs text-gray-600 mt-1">{industry.description}</p>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="bg-gray-50 px-6 py-4 text-center">
                                <p className="text-xs text-gray-500">
                                    Discover industry-specific Pay Per Click strategies tailored for your business.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>

            <Link
                href="/blog"
                className={`${url === '/blog' ? 'active' : ''} text-gray-700 group bg-white inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-4`}
            >
                Blog
            </Link>
        </>
    )
}

export default MenuLinks

{/* <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Search Engine Marketing</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-0 z-10 mt-5 flex w-screen max-w-max -translate-x-10 px-4">
                        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                                {leadgen.map((item) => (
                                    <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" aria-hidden="true" />
                                        </div>
                                        <div>
                                            <Link
                                                href={item.href}
                                                className="menu-links font-semibold text-gray-900"
                                            >
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-600">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="bg-gray-50 px-8 py-6">
                                <div className="flex items-center gap-x-3">
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">Shopping Services</h3>
                                    <p className="rounded-full bg-green-600/10 px-2.5 py-1.5 text-xs font-semibold text-green-600">Updated</p>
                                </div>
                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                    Enhance your digital footprint and increase your search visibility with our refined SEO strategies.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>

            <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Shopping</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                                {shopping.map((item) => (
                                    <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" aria-hidden="true" />
                                        </div>
                                        <div>
                                            <Link href={item.href} className="menu-links sfont-semibold text-gray-900">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-600">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="bg-gray-50 px-8 py-6">
                                <div className="flex items-center gap-x-3">
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">Shopping Services</h3>
                                    <p className="rounded-full bg-green-600/10 px-2.5 py-1.5 text-xs font-semibold text-green-600">Updated</p>
                                </div>
                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                    Discover a seamless shopping experience with hands on approach.  We've can enhanced every aspect your store to better meet your needs.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>
            <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Data Analytics</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                                {analytics.map((item) => (
                                    <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" aria-hidden="true" />
                                        </div>
                                        <div>
                                            <Link href={item.href} className="menu-links sfont-semibold text-gray-900">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-600">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="bg-gray-50 px-8 py-6">
                                <div className="flex items-center gap-x-3">
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">Data Analytics Services</h3>
                                    <p className="rounded-full bg-green-600/10 px-2.5 py-1.5 text-xs font-semibold text-green-600">Updated</p>
                                </div>
                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                    Transform your data into actionable insights and drive your business forward with our expert data analytics services.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover>
            <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>Web Development</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                                {development.map((item) => (
                                    <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" aria-hidden="true" />
                                        </div>
                                        <div>
                                            <Link href={item.href} className="menu-links sfont-semibold text-gray-900">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-600">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="bg-gray-50 px-8 py-6">
                                <div className="flex items-center gap-x-3">
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">Web Development</h3>
                                    <p className="rounded-full bg-green-600/10 px-2.5 py-1.5 text-xs font-semibold text-green-600">Updated</p>
                                </div>
                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                    Enhance your online presence & achieve your business goals with our expert web development.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover> */}
{/* <Popover className="relative mx-2">
                {({ open }) => (
                    <>
                        <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                            <span>Industries</span>
                            <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                        </PopoverButton>
                        <Transition
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <PopoverPanel className="absolute left-24 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                                <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                                    {seoIndustries.map((item) => (
                                        <a key={item.name} href={item.href} className="block p-2 hover:text-green-500">
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </PopoverPanel>
                        </Transition>
                    </>
                )}
            </Popover> */}
{/* <Popover className="relative mx-2">
                <PopoverButton className="group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span>AI & Automation</span>
                    <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </PopoverButton>

                <Transition
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                                {automation.map((item) => (
                                    <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                                        <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-green-600" aria-hidden="true" />
                                        </div>
                                        <div>
                                            <Link href={item.href} className="menu-links sfont-semibold text-gray-900">
                                                {item.name}
                                                <span className="absolute inset-0" />
                                            </Link>
                                            <p className="mt-1 text-gray-600">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="bg-gray-50 px-8 py-6">
                                <div className="flex items-center gap-x-3">
                                    <h3 className="text-sm font-semibold leading-6 text-gray-900">AI & Automation</h3>
                                    <p className="rounded-full bg-green-600/10 px-2.5 py-1.5 text-xs font-semibold text-green-600">Updated</p>
                                </div>
                                <p className="mt-2 text-sm leading-6 text-gray-600">
                                    Automate your workflows and streamline your business processes with our expert AI & automation services.
                                </p>
                            </div>
                        </div>
                    </PopoverPanel>
                </Transition>
            </Popover> */}
{/* <Link
                href="/web-design"
                className={`${url === '/low-code-no-code' ? 'active' : ''} text-gray-700 group bg-white inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700 mx-4`}>
                AI Automation
            </Link> */}

{/* <a
                // href="/courses"
                onClick={() => Swal.fire({
                    title: 'Courses',
                    text: 'We are currently working on this page. Please check back later.',
                    icon: 'info',
                    confirmButtonText: 'OK',
                    confirmButtonColor: 'green'
                })}
                className="text-gray-700 cursor-pointer group bg-white inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-4"
            >
                Courses
            </a> */}



{/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */ }
{/* <Link
                        href="/lead-generation"
                        className={`${url === '/lead-generation' ? 'active' : ''} text-gray-700 group bg-white inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700 mx-4`}>
                        <span>SEO &amp; Lead Generation</span>
                    </Link> */}
{/* <Link
                href="/about-us"
                className={`${url === '/about-us' ? 'active' : ''} text-gray-700 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-4`}
            >
                Who We Are
            </Link> */}
