import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from '@inertiajs/inertia-react';

function CookiePolicy() {
    const [cookie, setCookie] = useCookies(['cookieConsent']);

    // Load GTM and update consent based on the current cookie state
    useEffect(() => {
        // Prevent execution if cookieConsent is undefined (i.e., first visit with no choice made)
        if (cookie.cookieConsent === undefined) return;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'consent_update',
            'analytics_storage': cookie.cookieConsent ? 'granted' : 'denied',
            'ad_storage': cookie.cookieConsent ? 'granted' : 'denied'
        });

    }, [cookie.cookieConsent]); // Runs only when cookieConsent changes

    const handleCookieConsent = () => {
        setCookie('cookieConsent', true, { path: '/', maxAge: 31536000, sameSite: 'Strict' });

        setTimeout(() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'consent_update',
                'analytics_storage': 'granted',
                'ad_storage': 'granted'
            });
        }, 100); // Delay to allow state update
    };

    const handleDenyConsent = () => {
        setCookie('cookieConsent', false, { path: '/', maxAge: 31536000, sameSite: 'Strict' });

        setTimeout(() => {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'consent_update',
                'analytics_storage': 'denied',
                'ad_storage': 'denied'
            });
        }, 100); // Delay to allow state update
    };

    return (
        <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
            <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
                <p className="text-sm leading-6 text-gray-900">
                    This site uses cookies. We and selected third parties use cookies (or similar technologies) for technical purposes, to enhance and analyze site usage, to support our marketing efforts, and for other purposes described in our Cookie Policy. See our{' '}
                    <Link href="/privacy-policy" className="font-semibold text-emerald-600">
                        cookie policy
                    </Link>.
                </p>
                <div className="mt-4 flex items-center gap-x-5">
                    <button
                        onClick={handleCookieConsent}
                        type="button"
                        className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                        Accept all
                    </button>
                    <button
                        onClick={handleDenyConsent}
                        type="button"
                        className="rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                        Deny
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CookiePolicy;
