import React, { useEffect } from 'react'
import NewsletterForm from '../../Components/NewsletterForm/Index'
import ProposalForm from '../../Components/ProposalForm/Index'
import { Link } from '@inertiajs/inertia-react'

const navigation = {
    solutions: [
        { name: 'Local SEO', href: '/seo/local' },
        { name: 'SEO Services', href: '/seo/services' },
        { name: 'National SEO', href: '/seo/national' },
        { name: 'Enterprise SEO', href: '/seo/enterprise' },
        { name: 'Lead Generation', href: '/lead-generation' },
        { name: 'GMB Optimizations', href: '/seo/gmb-optimization' },
        { name: 'OnPage Optimization', href: '/seo/on-page-optimization' },
    ],
    consultation: [
        { name: 'Social Media Management', href: '/social/social-media-management' },
        { name: 'Zapier Consulting', href: '/automation/zapier-consulting' },
        { name: 'Make.com Consulting', href: '/automation/make-consulting' },
        { name: 'Pabbly Consulting', href: '/automation/pabbly-consulting' },
        { name: 'Shopify Consulting', href: '/shop/shopify-consulting' },
        { name: 'WooCommerce Consulting', href: '/shop/woocommerce-consulting' },
    ],
    ppc: [
        { name: 'Paid Ads', href: '/paid-ads' },
        { name: 'Google PPC Ads', href: '/ppc/google-ads' },
        { name: 'Facebook Ads', href: '/ppc/facebook-ads' },
        { name: 'Instagram Ads', href: '/ppc/instagram-ads' },
        { name: 'YouTube Ads', href: '/ppc/youtube-ads' },
        { name: 'TikTok Ads', href: '/ppc/tiktok-ads' },
        { name: 'Google Ads Exam', href: '/ppc/google-ads-exam' },
    ],
    analytics: [
        { name: 'Google Analytics Consulting', href: '/analytics/google-analytics-consulting' },
        { name: 'Segment.io Consulting', href: '/analytics/data-analytics' },
        { name: 'Mixpanel Consulting', href: '/analytics/mixpanel-consulting' },
        { name: 'Power BI Consulting', href: '/analytics/google-tag-manager-consulting' },
        { name: 'Elastic Stack Consulting', href: '/analytics/elastic-stack-consulting' },
        { name: 'Google Analytics Exam', href: '/analytics/google-analytics-exam' },
    ],
    development: [
        { name: 'Web Design', href: '/web-design' },
        { name: 'JavaScript Consulting', href: '/web-design/javascript-consulting' },
        { name: 'ReactJS Consulting', href: '/web-design/reactjs-consulting' },
        { name: 'Laravel Consulting', href: '/web-design/laravel-consulting' },
        { name: 'Ruby on Rails Consulting', href: '/web-design/ruby-on-rails-consulting' },
    ],
    custom: [
        { name: 'Blog', href: '/blog' },
        { name: 'Privacy Policy', href: '/privacy-policy ' },
        { name: 'Documentation', href: 'https://docs.gurulabs.dev' },
    ],

    social: [
        {
            name: 'Facebook',
            href: '//facebook.com/myadsgurus',
        },
        {
            name: 'Instagram',
            href: '//instagram.com/myadsgurus',
        },
        {
            name: 'Twitter',
            href: '//twitter.com/myadsgurus',
        },
        {
            name: 'Help @ Gurulabs',
            href: 'https://docs.gurulabs.dev'
        },
    ],
}

function Footer() {
    return (
        <footer className="bghero" aria-labelledby="footer-heading">
            <h2 id="footer-heading" className="sr-only">
                Footer
            </h2>
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                {/* Top Section */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    <div>
                        <h3 className="text-3xl text-gray-900 py-4">Get started with Guru Labs today!</h3>
                        <ProposalForm />
                    </div>
                    <div>
                        <img src="/frontend/images/png/footer-01.png" className="mx-auto" alt="Guru Labs" />
                    </div>
                </div>

                {/* Divider */}
                <svg className="py-8" viewBox="0 0 1196 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="1.10742" x2="1196" y2="1.10742" stroke="#48BB78" strokeWidth="2" />
                </svg>

                {/* Main Footer Links */}
                <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                    {/* SEO Services */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wide">SEO Services</h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {navigation.solutions.map((item) => (
                                <li key={item.name}>
                                    <Link href={item.href} className="text-base text-gray-700 hover:text-gray-900">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Consultation */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wide">Consultation</h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {navigation.consultation.map((item) => (
                                <li key={item.name}>
                                    <Link href={item.href} className="text-base text-gray-700 hover:text-gray-900">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* PPC Management */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wide">PPC Management</h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {navigation.ppc.map((item) => (
                                <li key={item.name}>
                                    <Link href={item.href} className="text-base text-gray-700 hover:text-gray-900">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Analytics */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wide">Analytics</h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {navigation.analytics.map((item) => (
                                <li key={item.name}>
                                    <Link href={item.href} className="text-base text-gray-700 hover:text-gray-900">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Web Development */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wide">Web Development</h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {navigation.development.map((item) => (
                                <li key={item.name}>
                                    <Link href={item.href} className="text-base text-gray-700 hover:text-gray-900">
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* Web Development */}
                    <div>
                        <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wide">Sitemap</h3>
                        <ul role="list" className="mt-4 space-y-4">
                            {navigation.custom.map((item) => (
                                <li key={item.name}>
                                    <a href={item.href} className="text-base text-gray-700 hover:text-gray-900">
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Newsletter Section */}
                <div className="mt-12">
                    <NewsletterForm />
                </div>

                {/* Bottom Section */}
                <div className="mt-8 border-t-2 border-green-500 pt-6 flex flex-wrap justify-center md:justify-between items-center text-center">
                    <div className="flex space-x-6 mb-4 md:mb-0">
                        {navigation.social.map((item) => (
                            <a key={item.name} href={item.href} className="text-gray-700 hover:text-green-600 text-sm md:text-base">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <p className="text-gray-700 text-sm md:text-base">
                        &copy; 2022 TwelveFifth, Inc. DBA Gurulabs, Inc. All rights reserved.
                        <Link href="/privacy-policy" className="ml-2 text-green-600 underline">
                            Privacy Policy
                        </Link>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
