import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import MetaHead from '../../../../Components/MetaHead'
import SocialProof from '../../../../Components/SocialProof/Index'
import CommerceSlider from '../../../../Components/CommerceSlider/Index'
import CalendarModal from '../../../../Layout/Modal/Index'
import Slider from '../../../../Components/Slider/Index'
import mixpanel from 'mixpanel-browser'
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index'
import AnalyticsPricing from '../../Analytics/AnalyticsPricing/Index'
import Process from '../../../../Components/Process/Index'
import { ChartBarIcon, Cog6ToothIcon, DocumentTextIcon, GiftIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/outline'
import CommerceFAQs from '../../../../Components/FAQs/CommerceFAQs/Index'

const Commerce = ({ commerce }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("eCommerce Page Visited")
    }, [])

    return (
        <PageLayout>
            <MetaHead title="Top eCommerce SEO & PPC Agency - Gurulabs | Shopify, WooCommerce" description="Boost your store's visibility and sales with Gurulabs, specializing in Shopify, WooCommerce, and Bagisto SEO, PPC, and Social Media Ads. Drive traffic and increase conversions." />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full lg:w-8/12 py-4">
                            <h1 className="text-3xl lg:text-6xl my-1">We help you create the best
                                <span className="text-green-500 relative mx-2">
                                    WooCommerce Experience
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                for your online store</h1>
                            <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Our experienced team of gurus use proven methods to increase your website’s search engine rankings and maximize your online traffic.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full lg:w-4/12 py-4">
                            <img src="/frontend/images/svg/icon-36.svg" className="mx-auto" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <AnalyticsPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Strategic eCommerce</span>
                            <span className="text-green-500 ml-2 relative">
                                SEO Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Offer value to target audience"
                            description="Provide your audience with something valuable in exchange for their contact information. This can be a discount, a free trial, a white paper, or an e-book related to your products or industry."
                            button="button-3"
                            Icon={UserGroupIcon}
                        />
                        <Process
                            title="Optimize your website or store"
                            description="Make sure your website is optimized for lead generation. Place opt-in forms in strategic locations on your site, such as the homepage, product pages, and blog posts. Make sure your forms are easy to fill out and offer a clear value proposition."
                            button="button-2"
                            Icon={DocumentTextIcon}
                        />
                        <Process
                            title="Leverage social media to drive traffic"
                            description="Use social media to reach a wider audience and drive traffic to your site. Offer exclusive discounts or promotions to your social media followers to encourage them to sign up for your email list or follow your store."
                            button="button-3"
                            Icon={Cog6ToothIcon}
                        />
                        <Process
                            title="Paid Ads to Capture leads"
                            description="Use paid advertising to reach a larger audience and generate leads. Platforms like Google Ads and Facebook Ads offer powerful targeting options that can help you reach your ideal customer."
                            button="button-2"
                            Icon={UserIcon}
                        />
                        <Process
                            title="Host events or webinars"
                            description="Host webinars and events related to your products or industry. This is a great way to showcase your expertise and build relationships with potential customers."
                            button="button-3"
                            Icon={GiftIcon}
                        />
                        <Process
                            title="Use email marketing"
                            description="Use email marketing to nurture your leads and keep them engaged with your brand. Send regular newsletters, special offers, and exclusive content to your email list to keep them interested in your products and services."
                            button="button-2"
                            Icon={ChartBarIcon}
                        />


                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Strategic eCommerce</span>
                            <span className="text-green-500 ml-2 relative">
                                PPC Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Offer value to target audience"
                            description="In the realm of e-commerce PPC services, offering your target audience something valuable in exchange for their contact information is a fundamental strategy. This value can come in various forms, such as a discount on their first purchase, a free trial of a premium product, an in-depth white paper, or an informative e-book that relates directly to your industry. By providing these incentives, you not only attract potential leads but also create a sense of goodwill and trust, which is essential for fostering long-term customer relationships."
                            button="button-3"
                            Icon={UserGroupIcon}
                        />
                        <Process
                            title="Optimize your website or store"
                            description="For an effective PPC campaign, ensuring that your e-commerce website is optimized for lead generation is critical. This involves strategically placing opt-in forms in prominent locations such as the homepage, product pages, and blog posts. These forms should be simple to fill out and clearly communicate the benefits of signing up. A well-optimized site enhances the user experience, making it easier for visitors to provide their contact information, thereby converting clicks into valuable leads."
                            button="button-2"
                            Icon={DocumentTextIcon}
                        />
                        <Process
                            title="Leverage social media to drive traffic"
                            description="Leveraging social media is a powerful way to extend your reach and drive traffic to your e-commerce site. By offering exclusive discounts or promotions to your social media followers, you create an incentive for them to engage with your brand and visit your site. Social media platforms also offer advanced targeting options, allowing you to reach a wider and more relevant audience, thus maximizing the effectiveness of your PPC campaigns."
                            button="button-3"
                            Icon={Cog6ToothIcon}
                        />
                        <Process
                            title="Paid Ads to Capture leads"
                            description="Paid advertising is a cornerstone of PPC services for e-commerce, allowing you to reach a broader audience and generate high-quality leads. Platforms like Google Ads and Facebook Ads offer sophisticated targeting options that enable you to focus your efforts on your ideal customer profiles. By investing in paid ads, you can drive targeted traffic to your site, increasing the likelihood of lead capture and conversion."
                            button="button-2"
                            Icon={UserIcon}
                        />
                        <Process
                            title="Host events or webinars"
                            description="Hosting events or webinars related to your products or industry is an excellent way to demonstrate your expertise and connect with potential customers. These interactive sessions provide valuable content and insights, helping to establish your brand as a thought leader. By engaging directly with attendees, you can build trust and foster relationships that can translate into leads and future sales."
                            button="button-3"
                            Icon={GiftIcon}
                        />
                        <Process
                            title="Use email marketing"
                            description="Email marketing remains a vital component of nurturing leads in e-commerce PPC services. Regularly sending newsletters, special offers, and exclusive content to your email list keeps your brand top of mind for potential customers. This ongoing engagement helps to build a relationship with your audience, encouraging them to return to your site and consider your products or services, ultimately driving conversions and boosting sales."
                            button="button-2"
                            Icon={ChartBarIcon}
                        />


                    </div>
                </div>
            </section>
            <PaidAdsPricingTable />
            <CommerceFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CommerceSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default Commerce
