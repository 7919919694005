import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import PersonalInformation from './PersonalInformation/Index';
import BusinessInformation from './BusinessInformation/Index';
import AdditionalDetails from './AdditionalDetails/Index';
import Swal from 'sweetalert2';
import { Inertia } from '@inertiajs/inertia';

const PaidAdsLeadsForm = ({ isOpen, setIsOpen, email }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [captcha, setCaptchaValue] = useState('');
    const [formValues, setFormValues] = useState({});

    const { register, handleSubmit, control, formState: { errors }, trigger } = useForm();

    // Function to push data to dataLayer
    const pushToDataLayer = (eventType, data = {}) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: eventType,
            ...data
        });
    };

    // Handle step change and push to dataLayer
    const handleNext = async () => {
        const isValid = await trigger(); // Trigger form validation
        console.log(isValid);
        if (isValid) {
            setCurrentStep(currentStep + 1);
            pushToDataLayer('form_step', {
                step: currentStep + 1,
                stepName: steps[currentStep + 1].title
            });
        }
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
        pushToDataLayer('form_step', {
            step: currentStep - 1,
            stepName: steps[currentStep - 1].title
        });
    };

    // On form submit
    const onSubmit = async (data) => {
        if (currentStep === 2) {
            try {
                await Inertia.post('/get-started', { ...data, captcha: captcha });

                // Push form submit success to dataLayer
                pushToDataLayer('form_submit', {
                    status: 'success',
                    formData: data
                });

                Swal.fire(
                    'Awesome!',
                    'Your information has been received!',
                    'success'
                );
            } catch (error) {
                console.log(error);

                // Push form submit failure to dataLayer
                pushToDataLayer('form_submit', {
                    status: 'failure',
                    errorMessage: error.message || 'Form submission failed'
                });

                toast.error('An error occurred, please try again');
            }
        } else {
            handleNext();
        }
    };

    useEffect(() => {
        const handleBodyScroll = () => {
            if (isOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        };

        handleBodyScroll();

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const steps = [
        {
            title: 'Personal Information',
            component: <PersonalInformation
                register={register}
                errors={errors}
                trigger={trigger}
                control={control}
                captcha={captcha}
                setCaptchaValue={setCaptchaValue}
                email={email}
            />
        },
        {
            title: 'Business Information',
            component: <BusinessInformation
                register={register}
                errors={errors}
                trigger={trigger}
                control={control}
                captcha={captcha}
                setCaptchaValue={setCaptchaValue}
            />
        },
        {
            title: 'Additional Details',
            component: <AdditionalDetails
                register={register}
                errors={errors}
                trigger={trigger}
                control={control}
                captcha={captcha}
                setCaptchaValue={setCaptchaValue}
            />
        }
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {steps[currentStep].component}
            <div className="flex flex-col sm:flex-row justify-between p-8">
                {currentStep > 0 && (
                    <button
                        type="button"
                        onClick={handlePrevious}
                        className="button bg-gray-300 hover:bg-gray-400 text-white py-2 px-4 rounded sm:w-auto w-full sm:mr-2 mb-2 sm:mb-0"
                    >
                        Previous
                    </button>
                )}
                {currentStep === steps.length - 1 && (
                    <button
                        type="submit"
                        className={`button bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ${currentStep === 0 ? 'w-full' : 'w-auto'} sm:mr-2 mb-2 sm:mb-0`}
                    >
                        Submit
                    </button>
                )}
                {currentStep < steps.length - 1 && (
                    <button
                        type="button"
                        onClick={handleNext}
                        className={`button bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${currentStep === 0 ? 'w-full' : 'w-auto'} sm:mr-2`}
                    >
                        Next
                    </button>
                )}
            </div>
        </form>
    )
}
export default PaidAdsLeadsForm;
