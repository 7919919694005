import React from 'react'
import toast from 'react-hot-toast'
import { Inertia } from '@inertiajs/inertia'

const emailValidation = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

const onSubmitHandler = (e) => {
    e.preventDefault();
    const inputEmail = e.target[0].value;

    emailValidation(inputEmail)
        ? Inertia.visit(`/get-started?email=${encodeURIComponent(inputEmail)}`)
        : toast.error('Please enter a valid email address', {
            duration: 4000,
            position: 'top-center',
            style: {
                borderRadius: '10px',
                background: 'linear-gradient(94.33deg, #48BB78 5.89%, #94F3C3 111.03%)',
            },
        });
};

function ProposalForm({ placeholder }) {
    return (
        <div className="py-8">
            <form className="flex flex-wrap relative" onSubmit={onSubmitHandler}>
                <input
                    type="email"
                    name="email"
                    required
                    className="w-full py-4 outline-none rounded-xl border-none relative"
                    placeholder={placeholder || "Please enter your email"}
                />
                <button className="absolute inset-0 my-2 text-xl mx-2 button w-1/3 left-auto rounded-lg text-gray-700 hover:bg-green-500">
                    Get Started
                </button>
            </form>
        </div>
    );
}

export default ProposalForm;
