import { Link } from '@inertiajs/inertia-react'
import React from 'react'


const citationPricing = [
    {
        id: 1,
        title: 'Basic Citation Plan',
        price: '$199',
        image: '/frontend/images/svg/icon-15.svg',
        description: 'Ideal for small businesses looking to establish their online presence with essential citation building services.',
        paymentUrl: '//app.gurulabs.dev/auth/login',
        features: [
            '100 Citations Built or Updated',
            'NAP Consistency Check',
            'Google My Business Optimization',
            'Basic Citation Report',
            'Entire Process Can Take Up to 14 Days',
        ]
    },
    {
        id: 2,
        title: 'Complete Citation Cleanup Service',
        price: '$299',
        image: '/frontend/images/svg/icon-16.svg',
        description: 'Ideal for businesses needing a thorough cleanup of existing citations to enhance local SEO performance.',
        paymentUrl: '//app.gurulabs.dev/auth/login',
        features: [
            'Complete Citation Cleanup Service',
            'Includes a FREE Manual Audit',
            'Suppress Duplicates',
            'Manual Outreach and Correction',
            '4-Part Process',
            'Double-Checked Verified Results',
            'Reseller Pricing Available',
            'Entire Process Can Take Up to 28 Days',
        ]
    },
    {
        id: 3,
        title: 'Citation Cleanup Service w/ API Sync',
        price: '$499',
        image: '/frontend/images/svg/icon-17.svg',
        description: 'Best for businesses looking for a comprehensive citation cleanup with advanced data sync capabilities.',
        paymentUrl: '',
        features: [
            'Complete Citation Cleanup Service w/ USA or Canada Big Data API Sync',
            'Ensure Corrections Stick',
            'Remove Duplicates',
            'Double-Checked and Verified Results',
            'Reseller Pricing Available',
            'No One Corrects More Bad Citations than Our Team',
            'Entire Process Can Take Up to 28 Days',
        ]
    },
]

const CitationPricing = ({ modalIsOpen, setModalIsOpen }) => {
    return (
        <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-20 px-2">
                {
                    citationPricing.map((item, index) => (
                        <div className="border-2 border-gray-100 py-6" key={index}>
                            {item.id === 2 && (
                                <div className="bg-green-300 flex justify-center items-center -mt-10">
                                    <h2 className="py-2 text-gray-700">Most Popular</h2>
                                </div>
                            )}
                            <div className="bg-white py-4 px-2">
                                <img src={item.image} className="py-2 px-2" alt={`${item.title}`} />
                                <h2 className="text-xl py-2">{item.title}</h2>
                                <p className="text-gray-700 py-2">{item.description}</p>
                                <h4 className="text-2xl text-gray-900 py-2">{item.price}</h4>
                                <ul className="py-4 px-2">
                                    {item.features.map((feature, index) => (
                                        <li className="flex items-center text-gray-700 py-4 px-2 border-b-2" key={index}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none"><g fill="#48BB78" clipPath="url(#a)"><path d="M9.13 12.024a.765.765 0 0 1-.506-.19L4.852 8.505a.766.766 0 0 1 1.013-1.15l3.236 2.845 6.754-6.706a.768.768 0 1 1 1.079 1.094L9.67 11.81a.768.768 0 0 1-.54.215Z" /><path d="M8.661 17.541a8.502 8.502 0 0 1-8.38-9.88A8.5 8.5 0 0 1 11.388.987a.766.766 0 1 1-.492 1.45 6.969 6.969 0 1 0 4.45 8.581.765.765 0 1 1 1.468.438 8.548 8.548 0 0 1-8.152 6.084Z" /></g><defs><clipPath id="a"><path fill="#fff" d="M.161.542h17v17h-17z" /></clipPath></defs></svg>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                                <div className="flex items-center text-gray-700 py-2 ml-4 px-2">
                                    <a
                                    href={item.paymentUrl}
                                    // onClick={() => }
                                    className="button w-full py-4 px-4 text-white text-center">
                                        Get Started
                                    </a>
                                    {/* <button
                                        onClick={() => {
                                            setModalIsOpen(!modalIsOpen)
                                            mixpanel.track("Schedule a meeting Citation Pricing button clicked");
                                        }}
                                        className="relative w-full py-3 px-3 top-1 right-2 button rounted-sm text-gray-700 hover:bg-green-500"
                                    >
                                        <span className="text-xl">Schedule a meeting</span>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="flex flex-col items-center">
                <p className="py-2 ">*Prices are based on monthly plans, and can be subscribed and unsubscribed at any time.</p>
                <a href="#pricing" className="button text-gray-700 py-4 px-4">See all plans details</a>
            </div>
        </div>
    )
}

export default CitationPricing
