import React from 'react'
import Image from '../Image/Index'

function Slider() {
    const slides = [
        { src: "/frontend/images/png/partnerlogo_01.webp", fallback: "/frontend/images/png/partnerlogo_01.png", alt: "Shopify Partners" },
        { src: "/frontend/images/png/partnerlogo_02.webp", fallback: "/frontend/images/png/partnerlogo_02.png", alt: "Google Cloud Partners" },
        { src: "/frontend/images/png/partnerlogo_03.webp", fallback: "/frontend/images/png/partnerlogo_03.png", alt: "Microsoft Partners" },
        { src: "/frontend/images/png/partnerlogo_04.webp", fallback: "/frontend/images/png/partnerlogo_04.png", alt: "Google Partners" },
        { src: "/frontend/images/png/partnerlogo_05.webp", fallback: "/frontend/images/png/partnerlogo_05.png", alt: "Meta Partners" },
        { src: "/frontend/images/png/partnerlogo_06.webp", fallback: "/frontend/images/png/partnerlogo_06.png", alt: "Amazon Partners" },
        { src: "/frontend/images/png/partnerlogo_01.webp", fallback: "/frontend/images/png/partnerlogo_01.png", alt: "Shopify Partners" },
        { src: "/frontend/images/png/partnerlogo_02.webp", fallback: "/frontend/images/png/partnerlogo_02.png", alt: "Google Cloud Partners" },
        { src: "/frontend/images/png/partnerlogo_03.webp", fallback: "/frontend/images/png/partnerlogo_03.png", alt: "Microsoft Partners" },
        { src: "/frontend/images/png/partnerlogo_04.webp", fallback: "/frontend/images/png/partnerlogo_04.png", alt: "Google Partners" },
        { src: "/frontend/images/png/partnerlogo_05.webp", fallback: "/frontend/images/png/partnerlogo_05.png", alt: "Meta Partners" },
        { src: "/frontend/images/png/partnerlogo_06.webp", fallback: "/frontend/images/png/partnerlogo_06.png", alt: "Amazon Partners" },
        { src: "/frontend/images/png/partnerlogo_01.webp", fallback: "/frontend/images/png/partnerlogo_01.png", alt: "Shopify Partners" },
        { src: "/frontend/images/png/partnerlogo_02.webp", fallback: "/frontend/images/png/partnerlogo_02.png", alt: "Google Cloud Partners" },
        { src: "/frontend/images/png/partnerlogo_03.webp", fallback: "/frontend/images/png/partnerlogo_03.png", alt: "Microsoft Partners" },
        { src: "/frontend/images/png/partnerlogo_04.webp", fallback: "/frontend/images/png/partnerlogo_04.png", alt: "Google Partners" },
        { src: "/frontend/images/png/partnerlogo_05.webp", fallback: "/frontend/images/png/partnerlogo_05.png", alt: "Meta Partners" },
        { src: "/frontend/images/png/partnerlogo_06.webp", fallback: "/frontend/images/png/partnerlogo_06.png", alt: "Amazon Partners" },
        { src: "/frontend/images/png/partnerlogo_01.webp", fallback: "/frontend/images/png/partnerlogo_01.png", alt: "Shopify Partners" },
        { src: "/frontend/images/png/partnerlogo_02.webp", fallback: "/frontend/images/png/partnerlogo_02.png", alt: "Google Cloud Partners" },
        { src: "/frontend/images/png/partnerlogo_03.webp", fallback: "/frontend/images/png/partnerlogo_03.png", alt: "Microsoft Partners" },
        { src: "/frontend/images/png/partnerlogo_04.webp", fallback: "/frontend/images/png/partnerlogo_04.png", alt: "Google Partners" },
        { src: "/frontend/images/png/partnerlogo_05.webp", fallback: "/frontend/images/png/partnerlogo_05.png", alt: "Meta Partners" },
        { src: "/frontend/images/png/partnerlogo_06.webp", fallback: "/frontend/images/png/partnerlogo_06.png", alt: "Amazon Partners" },
        { src: "/frontend/images/png/partnerlogo_01.webp", fallback: "/frontend/images/png/partnerlogo_01.png", alt: "Shopify Partners" },
        { src: "/frontend/images/png/partnerlogo_02.webp", fallback: "/frontend/images/png/partnerlogo_02.png", alt: "Google Cloud Partners" },
        { src: "/frontend/images/png/partnerlogo_03.webp", fallback: "/frontend/images/png/partnerlogo_03.png", alt: "Microsoft Partners" },
        { src: "/frontend/images/png/partnerlogo_04.webp", fallback: "/frontend/images/png/partnerlogo_04.png", alt: "Google Partners" },
        { src: "/frontend/images/png/partnerlogo_05.webp", fallback: "/frontend/images/png/partnerlogo_05.png", alt: "Meta Partners" },
        { src: "/frontend/images/png/partnerlogo_06.webp", fallback: "/frontend/images/png/partnerlogo_06.png", alt: "Amazon Partners" },
        { src: "/frontend/images/png/partnerlogo_01.webp", fallback: "/frontend/images/png/partnerlogo_01.png", alt: "Shopify Partners" },
        { src: "/frontend/images/png/partnerlogo_02.webp", fallback: "/frontend/images/png/partnerlogo_02.png", alt: "Google Cloud Partners" },
        { src: "/frontend/images/png/partnerlogo_03.webp", fallback: "/frontend/images/png/partnerlogo_03.png", alt: "Microsoft Partners" },
        { src: "/frontend/images/png/partnerlogo_04.webp", fallback: "/frontend/images/png/partnerlogo_04.png", alt: "Google Partners" },
        { src: "/frontend/images/png/partnerlogo_05.webp", fallback: "/frontend/images/png/partnerlogo_05.png", alt: "Meta Partners" },
        { src: "/frontend/images/png/partnerlogo_06.webp", fallback: "/frontend/images/png/partnerlogo_06.png", alt: "Amazon Partners" },
    ];

    return (
        <section>
            <div className="relative pt-4">
                <div className="slider">
                    <div className="slide-track">
                        {/* Duplicate images twice for seamless looping */}
                        {[...slides, ...slides].map((slide, index) => (
                            <div className="slide" key={index}>
                                <Image src={slide.src} fallback={slide.fallback} height={100} width={250} alt={slide.alt} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Slider;
