import React, { useEffect, useState } from 'react'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import PageLayout from '../../../../Layout/PageLayout/Index'
import MetaHead from '../../../../Components/MetaHead'
import Slider from '../../../../Components/Slider/Index'
import AutomationPricing from '../AutomationPricing/Index'
import mixpanel from 'mixpanel-browser'
import Newsletter from '../../../../Components/Newsletter/Index'
import CalendarModal from '../../../../Layout/Modal/Index'
import SocialProof from '../../../../Components/SocialProof/Index'
import PaidAdsPricingTable from '../../../../Components/PaidAdsPricingTable/Index'
import CommerceSlider from '../../../../Components/CommerceSlider/Index'
import Process from '../../../../Components/Process/Index'
import { SiIntegromat, SiSupabase, SiZapier } from '@icons-pack/react-simple-icons'
import AutomationHero from '../AutomationPageHero/Index'
import SuccessStory from '../../../../Components/SuccessStory/Index'


const MakePage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("Make.com Page Visited")
    }, [])

    return (
        <PageLayout>
            <MetaHead title="Process Automation and AI with Make.com | Gurulabs" description="Transform your business with Process Automation and AI using Make.com. Gurulabs helps you automate workflows and optimize operations efficiently." />
            <AutomationHero name="Make" />
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <AutomationPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SuccessStory setModalIsOpen={setModalIsOpen} />
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Streamline Your Workflow with</span>
                            <span className="text-green-500 ml-2 relative">
                                AI & Automation
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none">
                                <path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" />
                            </svg>
                        </h2>
                        <p className="text-base text-center">Empower your business with our AI & automation expertise using platforms like Zapier, Integromat, and Pabbly. Simplify complex tasks and boost efficiency without writing code.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="Define Objectives and Goals"
                            description="Begin by clearly defining your objectives and goals for using AI & Automation low code / no code platforms like Zapier, Integromat, and Pabbly. Identify the processes you want to automate and the outcomes you aim to achieve to ensure a focused and effective implementation."
                            button="button-3"
                            Icon={SiZapier}
                        />
                        <Process
                            title="Select the Right Platform"
                            description="Choose the platform that best suits your needs. Evaluate Zapier, Integromat, and Pabbly based on their features, integrations, ease of use, and pricing to find the one that aligns with your business requirements and technical capabilities."
                            button="button-2"
                            Icon={SiIntegromat}
                        />
                        <Process
                            title="Create Workflows"
                            description="Design and create workflows that automate your processes. Use the intuitive drag-and-drop interfaces provided by these platforms to connect apps and define actions that trigger specific tasks, ensuring a seamless and automated workflow."
                            button="button-3"
                            Icon={SiSupabase}
                        />
                        <Process
                            title="Test and Iterate"
                            description="Once your workflows are set up, test them thoroughly to ensure they function as expected. Identify any issues or bottlenecks and make necessary adjustments. Continuously iterate and optimize your workflows for maximum efficiency and effectiveness."
                            button="button-2"
                            Icon={SiZapier}
                        />
                        <Process
                            title="Monitor and Analyze"
                            description="Regularly monitor the performance of your automated workflows. Use built-in analytics and reporting tools to track key metrics and evaluate the impact on your business processes. This data-driven approach will help you make informed decisions and improvements."
                            button="button-3"
                            Icon={SiIntegromat}
                        />
                        <Process
                            title="Scale and Expand"
                            description="As you become more comfortable with the platform, look for opportunities to scale and expand your automation efforts. Explore advanced features and integrations to automate more complex processes, driving greater efficiency and productivity in your business."
                            button="button-2"
                            Icon={SiSupabase}
                        />
                    </div>
                </div>

            </section>
            <Newsletter />
            <SocialProof />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h1 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're your trusted partner </span>
                                    <span className="text-green-500 ml-2 relative">
                                        for Process Automation and AI
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h1>
                            </div>
                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">In the dynamic world of AI & Automation, low code / no code platforms like Zapier, Make.com (formerly Integromat), and Pabbly are revolutionizing how businesses automate their workflows. These platforms empower users to create complex automations without the need for extensive coding knowledge, making them accessible to a broader audience. Here’s a detailed guide on the setup process for these platforms.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>1. Define Objectives and Goals</strong></p>

                                <p className="text-xl py-4 text-gray-700">Start by clearly defining your objectives and goals. Understand what processes you aim to automate and the specific outcomes you want to achieve. Whether it's streamlining customer support, enhancing marketing efforts, or improving data synchronization, having a clear vision will guide you in choosing the right platform and designing effective workflows.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>2. Select the Right Platform</strong></p>

                                <p className="text-xl py-4 text-gray-700">Choosing the appropriate platform is crucial. Here’s a brief overview of Zapier, Make.com, and Pabbly to help you decide:</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Zapier:</strong> Known for its extensive range of integrations, <a href="/automation/zapier-consulting" className="font-bold underline">Zapier</a> connects over 3,000 apps, making it a versatile choice for automating various tasks. It's user-friendly and ideal for businesses looking to quickly set up automations across multiple apps.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Make.com:</strong> Previously known as Integromat, Make.com offers powerful visual tools to create detailed and complex workflows. It's perfect for users who need more granular control over their automations, including advanced data manipulation and conditional logic.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Pabbly:</strong> Pabbly Connect is a budget-friendly option that provides robust automation capabilities with a focus on affordability. It supports numerous integrations and is ideal for small to medium-sized businesses seeking a cost-effective automation solution.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>3. Create Workflows</strong></p>

                                <p className="text-xl py-4 text-gray-700">Once you’ve chosen a platform, start by creating your workflows:</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Zapier:</strong> Use Zapier’s intuitive interface to connect your apps. Create “Zaps” by selecting a trigger event in one app and defining actions to be performed in others. For example, when a new lead is added in your CRM, Zapier can automatically add the lead’s details to a Google Sheet and send a welcome email via your email marketing tool.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Make.com:</strong> Design detailed workflows using <a href="/automation/make-consulting" className="font-bold underline">Make.com’s</a> visual builder. Create “scenarios” that map out your automation steps. You can include filters, routers, and advanced logic to handle complex tasks. For instance, a scenario might watch for new orders in an e-commerce platform, validate order details, update inventory in real-time, and notify the shipping department.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Pabbly:</strong> Set up your workflows by creating “connections” between apps. Define triggers and actions similar to Zapier. <a href="/automation/pabbly-consulting" className="font-bold underline">Pabbly</a> also supports multi-step workflows, allowing you to build comprehensive automations. For example, when a new subscriber joins your mailing list, Pabbly can add the subscriber to a webinar platform and send a personalized welcome message.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>4. Test and Iterate</strong></p>

                                <p className="text-xl py-4 text-gray-700">Testing is a crucial step to ensure your workflows function as intended:</p>

                                <p className="text-xl py-4 text-gray-700">Run your workflows with test data to verify that each step performs correctly. Check for any errors or unexpected behavior. If an issue arises, troubleshoot by reviewing each step and making necessary adjustments. Continuous testing and iteration will help you refine your workflows for optimal performance.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>5. Monitor and Analyze</strong></p>

                                <p className="text-xl py-4 text-gray-700">After deploying your workflows, regular monitoring is essential:</p>

                                <p className="text-xl py-4 text-gray-700">Use the analytics and reporting tools provided by the platforms to track key metrics. Analyze the performance data to understand how well your automations are working and their impact on your business processes. This ongoing analysis will provide insights into areas for improvement and help you make informed decisions.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>6. Scale and Expand</strong></p>

                                <p className="text-xl py-4 text-gray-700">As you gain confidence in using these platforms, look for opportunities to scale your automation efforts:</p>

                                <p className="text-xl py-4 text-gray-700">Explore advanced features and integrations to automate more complex processes. For example, you might integrate AI tools to enhance your customer support automation or connect additional apps to streamline interdepartmental workflows. Scaling your automations will drive greater efficiency and productivity across your business.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Conclusion</strong></p>

                                <p className="text-xl py-4 text-gray-700">AI & Automation platforms like Zapier, Make.com, and Pabbly are transforming how businesses operate by enabling the creation of powerful automations without extensive coding. By following a structured setup process—defining objectives, selecting the right platform, creating and testing workflows, monitoring performance, and scaling—you can harness the full potential of these tools to enhance efficiency and drive growth in your business.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <CommerceSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
        </PageLayout>
    )
}
export default MakePage
