import React from 'react';


const PersonalInformation = ({ register, errors, trigger, control, email }) => {

    // Function to trigger validation for the entire form
    const triggerValidation = async () => {
        await trigger(); // Trigger validation for all fields
    };

    return (
        <div className="bg-white p-6">
            <div className='py-6'>
                <h3 className="text-base font-semibold leading-6 text-gray-900">Get a quote in your email</h3>
                <p className="mt-1 text-sm text-gray-500">Please add your information to receive a custom quote</p>
            </div>
            <div className="grid grid-cols-6 gap-2">
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">First name</label>
                    <input
                        required
                        type="text"
                        name="first_name"
                        id="first_name"
                        {...register('first_name', { required: 'First Name is required' })}
                        onBlur={triggerValidation}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-2 text-lg text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    {errors.first_name && <div className="text-red-500">{errors.first_name.message}</div>}
                </div>
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">Last name</label>
                    <input
                        required
                        type="text"
                        name="last_name"
                        id="last_name"
                        {...register('last_name', { required: 'Last Name is required' })}
                        onBlur={triggerValidation}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    {errors.last_name && <div className="text-red-500">{errors.last_name.message}</div>}
                </div>
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                    <input
                        required
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^\S+@\S+\.(com|net|org|edu|gov|dev|app|us|io)$/i,
                                message: 'Invalid email or unsupported TLD (.com, .net, etc.)'
                            }
                        })}
                        onBlur={triggerValidation}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    {errors.email && <div className="text-red-500">{errors.email.message}</div>}
                </div>
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="phone_number" className="block text-sm font-medium leading-6 text-gray-900">Phone Number</label>
                    <input
                        required
                        type="phone_number"
                        name="phone_number"
                        id="phone_number"
                        {...register('phone_number', { required: 'Phone Number is required' })}
                        onBlur={triggerValidation}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    {errors.phone_number && <div className="text-red-500">{errors.phone_number.message}</div>}
                </div>
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="company_name" className="block text-sm font-medium leading-6 text-gray-900">Company Name</label>
                    <input
                        required
                        type="company_name"
                        name="company_name"
                        id="company_name"
                        {...register('company_name', { required: 'Company Name is required' })}
                        onBlur={triggerValidation}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    {errors.company_name && <div className="text-red-500">{errors.company_name.message}</div>}
                </div>
                <div className="col-span-6 sm:col-span-6">
                    <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">Website</label>
                    <input
                        type="url"
                        name="website"
                        id="website"
                        {...register('website')}
                        onBlur={triggerValidation}
                        className="mt-2 block w-full rounded-md border-0 px-3 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    />
                    {errors.website && <div className="text-red-500">{errors.website.message}</div>}
                </div>
            </div>
        </div>
    );
};

export default PersonalInformation;
