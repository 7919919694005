import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import mixpanel from 'mixpanel-browser';

function CalendarModal({ isOpen, setIsOpen }) {
    const [calendarUrl, setCalendarUrl] = useState(null);

    useEffect(() => {
        // Disable scrolling when modal is open
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset'; // Restore scrolling when modal is closed
        }

        const close = (e) => {
            if (e.keyCode === 27 && isOpen) {
                setIsOpen(false);
                mixpanel.track('Modal Closed', { action: 'Closed using Esc key' });
            }
        };
        window.addEventListener('keydown', close);

        // Cleanup when the component unmounts or `isOpen` changes
        return () => {
            document.body.style.overflow = 'unset'; // Ensure scrolling is restored
            window.removeEventListener('keydown', close);
        };
    }, [isOpen, setIsOpen]);

    const handleCloseModal = () => {
        setIsOpen(false);
        document.body.style.overflow = 'unset'; // Restore scrolling when closing the modal
        mixpanel.track('Modal Closed', { action: 'Closed manually' });
    };

    const handleOpenCalendar = (type) => {
        if (type === 'paid') {
            setCalendarUrl(
                'https://calendly.com/gurulabs/paid-hourly?embed_domain=www.gurulabs.dev&embed_type=PopupText&hide_event_type_details=1&hide_gdpr_banner=1&primary_color=22c55e'
            );
            mixpanel.track('Calendar Opened', { type: 'Paid Consultation' });
        } else {
            setCalendarUrl(
                'https://calendly.com/gurulabs/30min?embed_domain=www.gurulabs.dev&embed_type=PopupText&hide_event_type_details=1&hide_gdpr_banner=1&primary_color=22c55e'
            );
            mixpanel.track('Calendar Opened', { type: 'Unpaid Consultation' });
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={false}
            onRequestClose={handleCloseModal}
            className="relative w-full max-w-md mx-auto my-20 bg-white rounded-lg shadow-lg overflow-hidden"
            overlayClassName="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-60"
        >
            {/* Close Button */}
            <button
                onClick={handleCloseModal}
                className="absolute top-4 right-4 text-black hover:text-gray-700 focus:outline-none z-50"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>
            {!calendarUrl ? (
                <div className="text-center p-6">
                    <h2 className="text-xl font-bold mb-4">Select a Calendar</h2>
                    <div className="flex justify-center gap-4 mt-4">
                        <button
                            className="button bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600"
                            onClick={() => handleOpenCalendar('paid')}
                        >
                            Paid Consultation
                        </button>
                        <button
                            className="button bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
                            onClick={() => handleOpenCalendar('unpaid')}
                        >
                            Free Consultation
                        </button>
                    </div>
                </div>
            ) : (
                <iframe
                    src={calendarUrl}
                    height="400px"
                    width="100%"
                    className="border-none"
                ></iframe>
            )}
        </Modal>
    );
}

export default CalendarModal;
