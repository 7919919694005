import { Inertia } from '@inertiajs/inertia';
import React from 'react'
import toast from 'react-hot-toast'

const emailValidation = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

const onSubmitHandler = (e) => {
    e.preventDefault();
    const inputEmail = e.target[0].value;
    console.log(inputEmail);
    emailValidation(inputEmail) ?
        Inertia.visit(`/get-started?email=${encodeURIComponent(inputEmail)}`) :
        toast.error('Please enter a valid email address', {
            duration: 4000,
            position: 'top-center',
            style: {
                borderRadius: '10px',
                background: 'linear-gradient(94.33deg, #48BB78 5.89%, #94F3C3 111.03%)',
            },
        });
};

function LeadsForm({ placeholder }) {
    return (
        <>
            <form onSubmit={onSubmitHandler}>
                <input
                    type="email"
                    name="email"
                    required
                    className="py-3 px-3 border border-gray-300 rounded-md w-full lg:w-8/12 mr-1"
                    placeholder={placeholder || "Please enter your email"}
                />
                <button className="mt-2 lg:-mt-2 text-xl relative py-3 px-3 button rounded-sm text-gray-700 hover:bg-green-500 w-full lg:w-3/12">
                    Get a proposal
                </button>
            </form>
        </>
    )
}

export default LeadsForm;
