import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import MenuLinks from "../MenuLinks/Index";
import MobileMenuLinks from "../MobileMenu/Index";
import { Link, usePage } from "@inertiajs/inertia-react";
import mixpanel from "mixpanel-browser";
import Swal from "sweetalert2";
import { Inertia } from "@inertiajs/inertia";

function Navbar({ modalIsOpen, setModalIsOpen }) {
    const [isOpen, setIsOpen] = useState(false);
    const { auth } = usePage().props;
    const { user } = auth;

    const handleSignOut = async (e) => {
        e.preventDefault();

        await Inertia.post("/logout");
        Swal.fire({
            title: "Success!",
            text: "You have been logged out.",
            icon: "success",
            confirmButtonText: "OK",
        });
    };

    const trackClick = (buttonName) => {
        gtag("event", "Navbar Click", {
            event_category: "Navigation",
            event_label: buttonName,
        });
        mixpanel.track(`Navbar ${buttonName} clicked`);
    };

    return (
        <Disclosure as="nav" className="bg-white py-2 border-0 border-gray-200">
            {({ open }) => (
                <>
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between h-16">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <Link href="/">
                                        <img
                                            src="/frontend/images/png/logo-light.png"
                                            height={64}
                                            width={92}
                                            aria-label="Gurulabs Logo"
                                            className="h-16 max-w-full"
                                            alt="Gurulabs Logo"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="hidden xl:block sm:ml-6">
                                <div className="flex">
                                    <MenuLinks />
                                </div>
                            </div>
                            <div className="hidden sm:ml-4 xl:block">
                                <div className="inline-flex items-center">
                                    <div className="inline-flex items-center">
                                        <a
                                            href="//app.gurulabs.dev/auth/login"
                                            onClick={() => trackClick("Desktop Login Button")}
                                            className="text-xl pt-1 text-gray-700 mr-6"
                                        >
                                            Login
                                        </a>
                                    </div>
                                    <button
                                        onClick={() => {
                                            setModalIsOpen(!modalIsOpen);
                                            trackClick("Schedule a Meeting");
                                        }}
                                        className="relative py-3 px-3 top-1 right-2 button rounded-sm text-xl text-gray-900 hover:bg-green-700"
                                    >
                                        Schedule a meeting
                                    </button>
                                </div>
                            </div>
                            <div className="-mr-2 flex xl:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-10 w-10" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-10 w-10" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="xl:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            <MobileMenuLinks />
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-700">
                            <div className="mt-6 px-2">
                                <a
                                    href="//app.gurulabs.dev/register"
                                    onClick={() => trackClick("Mobile Navbar Register Button")}
                                    className="inline-flex relative justify-center items-center py-2 px-4 w-full font-medium leading-6 text-white bg-green-400 rounded-md border border-transparent border-solid cursor-pointer box-border"
                                >
                                    Register Now
                                </a>
                                <p className="my-4 text-center text-base font-medium text-gray-900">
                                    Existing customer?
                                    <a
                                        href="//app.gurulabs.dev"
                                        onClick={() => trackClick("Mobile Navbar Sign In Button")}
                                        className="text-green-400"
                                    >
                                        Sign in
                                    </a>
                                </p>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
}

export default Navbar;
