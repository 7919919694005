import React, { useState } from 'react';


const questions = [
    {
        "question": "Optimization Score is made up of over 50 recommendations to optimize Search campaigns.",
        "answers": [
            { "text": "True", "isCorrect": true },
            { "text": "False", "isCorrect": false }
        ]
    },
    {
        "question": "Which of the following factors wouldn’t change an account’s optimization score?",
        "answers": [
            { "text": "Changes in auction dynamics", "isCorrect": false },
            { "text": "Changes in tracked conversions", "isCorrect": false },
            { "text": "Renaming campaigns", "isCorrect": true },
            { "text": "Shifts in spend mix", "isCorrect": false }
        ]
    },
    {
        "question": "How does Google Ads generate responsive search ads?",
        "answers": [
            { "text": "Google Ads creates them based on existing ad copy, landing pages, and extensions", "isCorrect": false },
            { "text": "Google Ads creates them based on existing high performing ad copy", "isCorrect": false },
            { "text": "Google Ads mixes and matches headlines and description lines that have been provided", "isCorrect": true },
            { "text": "Google Ads mixes and matches headlines and URLs that have been provided", "isCorrect": false }
        ]
    },
    {
        "question": "How many ads should be implemented per ad group?",
        "answers": [
            { "text": "One or two", "isCorrect": false },
            { "text": "Only one", "isCorrect": false },
            { "text": "Three to five", "isCorrect": true },
            { "text": "Two to three", "isCorrect": false }
        ]
    },
    {
        "question": "Which are the three required parts of a text ad?",
        "answers": [
            { "text": "Description, path fields, and headline", "isCorrect": false },
            { "text": "Description, path fields, and URL", "isCorrect": false },
            { "text": "Headline, description, and path fields", "isCorrect": true },
            { "text": "Headline, description, and URL", "isCorrect": false }
        ]
    },
    {
        "question": "What is the key value proposition of Google Search campaigns?",
        "answers": [
            { "text": "Appear as a text ad, a video ad, or a banner ad on one of Google’s partner sites", "isCorrect": false },
            { "text": "Influence organic results", "isCorrect": false },
            { "text": "Reach people on Google.com that might be interested in your brand, regardless of what they’re searching for", "isCorrect": false },
            { "text": "Show your ads when a customer is searching for your product or service", "isCorrect": true }
        ]
    },
    {
        "question": "Which part of a Search ad isn’t automatically generated by Dynamic Search Ads?",
        "answers": [
            { "text": "All of these are automatically generated", "isCorrect": false },
            { "text": "Description line", "isCorrect": true },
            { "text": "Destination URL", "isCorrect": false },
            { "text": "Headline", "isCorrect": false }
        ]
    },
    {
        "question": "Which two people might see an ad with the keyword +black +shirt (set as broad match modifier)?",
        "answers": [
            { "text": "Someone searching for the term 'I want to buy a black shirt'", "isCorrect": true },
            { "text": "Someone searching for the term 'black button shirt'", "isCorrect": true },
            { "text": "Someone searching for the term 'shirt'", "isCorrect": false },
            { "text": "Someone searching for the term 'shirt brown'", "isCorrect": false }
        ]
    },
    {
        "question": "How can Google Ads help you advance your business goals?",
        "answers": [
            { "text": "By building awareness of your brand", "isCorrect": true },
            { "text": "By driving online, in-app, in-person, and over-the-phone sales", "isCorrect": true },
            { "text": "By influencing consideration of your products and services", "isCorrect": true },
            { "text": "All of the above", "isCorrect": true }
        ]
    },
    {
        "question": "Match each autobidding strategy to the right campaign goal.",
        "answers": [
            { "text": "Target CPA: Conversions", "isCorrect": true },
            { "text": "Target ROAS: Revenue", "isCorrect": true },
            { "text": "Maximize Clicks: Traffic", "isCorrect": true },
            { "text": "Target Impression Share: Visibility", "isCorrect": true }
        ]
    },
    {
        "question": "Optimization Score is made up of over 50 recommendations to optimize Search campaigns.",
        "answers": [
            { "text": "True", "isCorrect": true },
            { "text": "False", "isCorrect": false }
        ]
    },
    {
        "question": "Which of the following factors wouldn’t change an account’s optimization score?",
        "answers": [
            { "text": "Changes in auction dynamics", "isCorrect": false },
            { "text": "Changes in tracked conversions", "isCorrect": false },
            { "text": "Renaming campaigns", "isCorrect": true },
            { "text": "Shifts in spend mix", "isCorrect": false }
        ]
    },
    {
        "question": "How does Google Ads generate responsive search ads?",
        "answers": [
            { "text": "Google Ads creates them based on existing ad copy, landing pages, and extensions", "isCorrect": false },
            { "text": "Google Ads creates them based on existing high performing ad copy", "isCorrect": false },
            { "text": "Google Ads mixes and matches headlines and description lines that have been provided", "isCorrect": true },
            { "text": "Google Ads mixes and matches headlines and URLs that have been provided", "isCorrect": false }
        ]
    },
    {
        "question": "How many ads should be implemented per ad group?",
        "answers": [
            { "text": "One or two", "isCorrect": false },
            { "text": "Only one", "isCorrect": false },
            { "text": "Three to five", "isCorrect": true },
            { "text": "Two to three", "isCorrect": false }
        ]
    },
    {
        "question": "Which are the three required parts of a text ad?",
        "answers": [
            { "text": "Description, path fields, and headline", "isCorrect": false },
            { "text": "Description, path fields, and URL", "isCorrect": false },
            { "text": "Headline, description, and path fields", "isCorrect": true },
            { "text": "Headline, description, and URL", "isCorrect": false }
        ]
    },
    {
        "question": "What is the key value proposition of Google Search campaigns?",
        "answers": [
            { "text": "Appear as a text ad, a video ad, or a banner ad on one of Google’s partner sites", "isCorrect": false },
            { "text": "Influence organic results", "isCorrect": false },
            { "text": "Reach people on Google.com that might be interested in your brand, regardless of what they’re searching for", "isCorrect": false },
            { "text": "Show your ads when a customer is searching for your product or service", "isCorrect": true }
        ]
    },
    {
        "question": "Which part of a Search ad isn’t automatically generated by Dynamic Search Ads?",
        "answers": [
            { "text": "All of these are automatically generated", "isCorrect": false },
            { "text": "Description line", "isCorrect": true },
            { "text": "Destination URL", "isCorrect": false },
            { "text": "Headline", "isCorrect": false }
        ]
    },
    {
        "question": "Which two people might see an ad with the keyword +black +shirt (set as broad match modifier)?",
        "answers": [
            { "text": "Someone searching for the term 'I want to buy a black shirt'", "isCorrect": true },
            { "text": "Someone searching for the term 'black button shirt'", "isCorrect": true },
            { "text": "Someone searching for the term 'shirt'", "isCorrect": false },
            { "text": "Someone searching for the term 'shirt brown'", "isCorrect": false }
        ]
    },
    {
        "question": "How can Google Ads help you advance your business goals?",
        "answers": [
            { "text": "By building awareness of your brand", "isCorrect": true },
            { "text": "By driving online, in-app, in-person, and over-the-phone sales", "isCorrect": true },
            { "text": "By influencing consideration of your products and services", "isCorrect": true },
            { "text": "All of the above", "isCorrect": true }
        ]
    },
    {
        "question": "Match each autobidding strategy to the right campaign goal.",
        "answers": [
            { "text": "Target CPA: Conversions", "isCorrect": true },
            { "text": "Target ROAS: Revenue", "isCorrect": true },
            { "text": "Maximize Clicks: Traffic", "isCorrect": true },
            { "text": "Target Impression Share: Visibility", "isCorrect": true }
        ]
    },
    {
        "question": "What is the primary purpose of UTM parameters in Google Analytics?",
        "answers": [
            {
                "text": "To improve website speed",
                "isCorrect": false
            },
            {
                "text": "To track campaign performance",
                "isCorrect": true
            },
            {
                "text": "To increase organic search ranking",
                "isCorrect": false
            },
            {
                "text": "To enhance user experience",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which report provides insight into which devices visitors use to access a website?",
        "answers": [
            {
                "text": "Device Report",
                "isCorrect": false
            },
            {
                "text": "Technology Report",
                "isCorrect": false
            },
            {
                "text": "Mobile Overview Report",
                "isCorrect": true
            },
            {
                "text": "Acquisition Report",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "What is the default session timeout in Google Analytics?",
        "answers": [
            {
                "text": "10 minutes",
                "isCorrect": false
            },
            {
                "text": "30 minutes",
                "isCorrect": true
            },
            {
                "text": "1 hour",
                "isCorrect": false
            },
            {
                "text": "24 hours",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which metric shows the number of times users have seen a specific page?",
        "answers": [
            {
                "text": "Sessions",
                "isCorrect": false
            },
            {
                "text": "Pageviews",
                "isCorrect": true
            },
            {
                "text": "Bounce Rate",
                "isCorrect": false
            },
            {
                "text": "Click-Through Rate",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which of the following is a benefit of using Google Analytics segments?",
        "answers": [
            {
                "text": "They help analyze specific subsets of data",
                "isCorrect": true
            },
            {
                "text": "They improve SEO rankings",
                "isCorrect": false
            },
            {
                "text": "They increase conversion rates",
                "isCorrect": false
            },
            {
                "text": "They replace goals in Google Analytics",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "What type of traffic is classified as 'Referral' in Google Analytics?",
        "answers": [
            {
                "text": "Visitors from social media ads",
                "isCorrect": false
            },
            {
                "text": "Visitors who type the website URL directly",
                "isCorrect": false
            },
            {
                "text": "Visitors who click a link on another website",
                "isCorrect": true
            },
            {
                "text": "Visitors from paid search ads",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which type of goal measures a user action such as a video play or button click?",
        "answers": [
            {
                "text": "Destination Goal",
                "isCorrect": false
            },
            {
                "text": "Duration Goal",
                "isCorrect": false
            },
            {
                "text": "Event Goal",
                "isCorrect": true
            },
            {
                "text": "Pages per Session Goal",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which report provides insights into the path users take before converting?",
        "answers": [
            {
                "text": "Acquisition Report",
                "isCorrect": false
            },
            {
                "text": "Multi-Channel Funnels Report",
                "isCorrect": true
            },
            {
                "text": "Real-Time Report",
                "isCorrect": false
            },
            {
                "text": "User Flow Report",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which metric measures the percentage of users who return to a website?",
        "answers": [
            {
                "text": "Bounce Rate",
                "isCorrect": false
            },
            {
                "text": "Returning Visitors",
                "isCorrect": true
            },
            {
                "text": "Exit Rate",
                "isCorrect": false
            },
            {
                "text": "Conversion Rate",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which tool helps measure the effectiveness of digital marketing campaigns?",
        "answers": [
            {
                "text": "Google Optimize",
                "isCorrect": false
            },
            {
                "text": "Google Analytics",
                "isCorrect": true
            },
            {
                "text": "Google Search Console",
                "isCorrect": false
            },
            {
                "text": "Google My Business",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which traffic source is NOT automatically tracked in Google Analytics?",
        "answers": [
            {
                "text": "Organic Search",
                "isCorrect": false
            },
            {
                "text": "Direct Traffic",
                "isCorrect": false
            },
            {
                "text": "Referral Traffic",
                "isCorrect": false
            },
            {
                "text": "Email Campaigns",
                "isCorrect": true
            }
        ]
    },
    {
        "question": "What is the purpose of the Google Analytics 'Goals' feature?",
        "answers": [
            {
                "text": "To track user satisfaction",
                "isCorrect": false
            },
            {
                "text": "To measure website revenue",
                "isCorrect": false
            },
            {
                "text": "To measure specific user interactions",
                "isCorrect": true
            },
            {
                "text": "To track search engine rankings",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which Google Analytics feature allows comparing the performance of different user groups?",
        "answers": [
            {
                "text": "Cohort Analysis",
                "isCorrect": true
            },
            {
                "text": "Audience Overview",
                "isCorrect": false
            },
            {
                "text": "User Flow",
                "isCorrect": false
            },
            {
                "text": "Acquisition Overview",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which report shows real-time user activity on a website?",
        "answers": [
            {
                "text": "Behavior Report",
                "isCorrect": false
            },
            {
                "text": "Real-Time Report",
                "isCorrect": true
            },
            {
                "text": "Acquisition Report",
                "isCorrect": false
            },
            {
                "text": "User Flow Report",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which report shows where website visitors are located geographically?",
        "answers": [
            {
                "text": "Location Report",
                "isCorrect": false
            },
            {
                "text": "Geo Report",
                "isCorrect": false
            },
            {
                "text": "Audience > Geo > Location",
                "isCorrect": true
            },
            {
                "text": "Acquisition > Geo > Location",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which metric measures the effectiveness of marketing efforts by showing the percentage of conversions per visitor?",
        "answers": [
            {
                "text": "Bounce Rate",
                "isCorrect": false
            },
            {
                "text": "Conversion Rate",
                "isCorrect": true
            },
            {
                "text": "Click-Through Rate",
                "isCorrect": false
            },
            {
                "text": "Session Duration",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which type of Google Analytics goal would track when a user spends 5 minutes on a page?",
        "answers": [
            {
                "text": "Event Goal",
                "isCorrect": false
            },
            {
                "text": "Destination Goal",
                "isCorrect": false
            },
            {
                "text": "Pages per Session Goal",
                "isCorrect": false
            },
            {
                "text": "Duration Goal",
                "isCorrect": true
            }
        ]
    },
    {
        "question": "Which Google Analytics feature allows tracking of interactions beyond a website (e.g., mobile apps)?",
        "answers": [
            {
                "text": "Cross-Domain Tracking",
                "isCorrect": false
            },
            {
                "text": "Event Tracking",
                "isCorrect": false
            },
            {
                "text": "Google Analytics 4 (GA4)",
                "isCorrect": true
            },
            {
                "text": "Behavior Flow",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which metric measures the percentage of users who complete a specific goal or desired action?",
        "answers": [
            {
                "text": "Click-Through Rate",
                "isCorrect": false
            },
            {
                "text": "Exit Rate",
                "isCorrect": false
            },
            {
                "text": "Conversion Rate",
                "isCorrect": true
            },
            {
                "text": "Bounce Rate",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which Google Analytics feature allows businesses to track users across different devices?",
        "answers": [
            {
                "text": "Cross-Device Tracking",
                "isCorrect": true
            },
            {
                "text": "Event Tracking",
                "isCorrect": false
            },
            {
                "text": "User Flow",
                "isCorrect": false
            },
            {
                "text": "Cohort Analysis",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which metric in Google Analytics measures the total number of user interactions on a website?",
        "answers": [
            {
                "text": "Pageviews",
                "isCorrect": false
            },
            {
                "text": "Sessions",
                "isCorrect": false
            },
            {
                "text": "Engagements",
                "isCorrect": true
            },
            {
                "text": "Bounce Rate",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "What is the purpose of enhanced eCommerce tracking in Google Analytics?",
        "answers": [
            {
                "text": "To track product performance and transactions",
                "isCorrect": true
            },
            {
                "text": "To improve website speed",
                "isCorrect": false
            },
            {
                "text": "To collect user emails",
                "isCorrect": false
            },
            {
                "text": "To display pop-up messages",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which type of goal in Google Analytics tracks form submissions and newsletter sign-ups?",
        "answers": [
            {
                "text": "Event Goal",
                "isCorrect": true
            },
            {
                "text": "Destination Goal",
                "isCorrect": false
            },
            {
                "text": "Duration Goal",
                "isCorrect": false
            },
            {
                "text": "Pages per Session Goal",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which report in Google Analytics allows businesses to see how users found their website?",
        "answers": [
            {
                "text": "Acquisition Report",
                "isCorrect": true
            },
            {
                "text": "Behavior Flow Report",
                "isCorrect": false
            },
            {
                "text": "Cohort Analysis Report",
                "isCorrect": false
            },
            {
                "text": "Landing Page Report",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "What does the 'Exit Pages' report in Google Analytics show?",
        "answers": [
            {
                "text": "The pages where users most frequently leave the site",
                "isCorrect": true
            },
            {
                "text": "The first pages users see when they land on a site",
                "isCorrect": false
            },
            {
                "text": "The total time spent on a website",
                "isCorrect": false
            },
            {
                "text": "The number of new visitors to a site",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which Google Analytics feature helps measure the effectiveness of A/B testing?",
        "answers": [
            {
                "text": "Google Optimize",
                "isCorrect": true
            },
            {
                "text": "User Flow",
                "isCorrect": false
            },
            {
                "text": "Event Tracking",
                "isCorrect": false
            },
            {
                "text": "Site Speed Report",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which audience type allows businesses to track visitors who abandoned their shopping cart?",
        "answers": [
            {
                "text": "Remarketing Audiences",
                "isCorrect": true
            },
            {
                "text": "Affinity Audiences",
                "isCorrect": false
            },
            {
                "text": "Demographic Audiences",
                "isCorrect": false
            },
            {
                "text": "Lookalike Audiences",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which feature in Google Analytics lets businesses compare different traffic sources?",
        "answers": [
            {
                "text": "Source/Medium Report",
                "isCorrect": true
            },
            {
                "text": "Exit Pages Report",
                "isCorrect": false
            },
            {
                "text": "Page Timing Report",
                "isCorrect": false
            },
            {
                "text": "Real-Time Overview",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which Google Analytics metric measures how far down a page users scroll?",
        "answers": [
            {
                "text": "Event Tracking",
                "isCorrect": true
            },
            {
                "text": "Time on Page",
                "isCorrect": false
            },
            {
                "text": "Pageviews",
                "isCorrect": false
            },
            {
                "text": "Sessions",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which feature in Google Analytics can be used to measure video engagement?",
        "answers": [
            {
                "text": "Event Tracking",
                "isCorrect": true
            },
            {
                "text": "User Flow",
                "isCorrect": false
            },
            {
                "text": "Bounce Rate",
                "isCorrect": false
            },
            {
                "text": "Cohort Analysis",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "What is a key benefit of using Google Analytics goals?",
        "answers": [
            {
                "text": "To track user actions like purchases and sign-ups",
                "isCorrect": true
            },
            {
                "text": "To improve page speed",
                "isCorrect": false
            },
            {
                "text": "To identify duplicate users",
                "isCorrect": false
            },
            {
                "text": "To display targeted ads",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which metric in Google Analytics measures how quickly a page loads?",
        "answers": [
            {
                "text": "Page Load Time",
                "isCorrect": true
            },
            {
                "text": "Time on Site",
                "isCorrect": false
            },
            {
                "text": "Bounce Rate",
                "isCorrect": false
            },
            {
                "text": "Exit Rate",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which Google Analytics report helps identify the best-performing landing pages?",
        "answers": [
            {
                "text": "Landing Pages Report",
                "isCorrect": true
            },
            {
                "text": "Exit Pages Report",
                "isCorrect": false
            },
            {
                "text": "Audience Overview",
                "isCorrect": false
            },
            {
                "text": "Source/Medium Report",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which Google Analytics feature tracks user interactions with a chatbot?",
        "answers": [
            {
                "text": "Event Tracking",
                "isCorrect": true
            },
            {
                "text": "Cohort Analysis",
                "isCorrect": false
            },
            {
                "text": "Real-Time Report",
                "isCorrect": false
            },
            {
                "text": "Geo Report",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which feature helps businesses track how many users complete a multi-step form?",
        "answers": [
            {
                "text": "Funnel Visualization",
                "isCorrect": true
            },
            {
                "text": "Exit Pages Report",
                "isCorrect": false
            },
            {
                "text": "Acquisition Report",
                "isCorrect": false
            },
            {
                "text": "User Flow",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which traffic source in Google Analytics represents visitors who type a website URL directly?",
        "answers": [
            {
                "text": "Direct Traffic",
                "isCorrect": true
            },
            {
                "text": "Referral Traffic",
                "isCorrect": false
            },
            {
                "text": "Organic Search",
                "isCorrect": false
            },
            {
                "text": "Paid Search",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "Which feature helps businesses track scroll depth on a webpage?",
        "answers": [
            {
                "text": "Event Tracking",
                "isCorrect": true
            },
            {
                "text": "Behavior Flow",
                "isCorrect": false
            },
            {
                "text": "Real-Time Report",
                "isCorrect": false
            },
            {
                "text": "Audience Report",
                "isCorrect": false
            }
        ]
    },
    {
        "question": "What is the benefit of using Google Analytics annotations?",
        "answers": [
            {
                "text": "To add notes about traffic spikes or campaign launches",
                "isCorrect": true
            },
            {
                "text": "To modify user tracking settings",
                "isCorrect": false
            },
            {
                "text": "To create new goals",
                "isCorrect": false
            },
            {
                "text": "To increase site speed",
                "isCorrect": false
            }
        ]
    }
]


function AnalyticsQuiz() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [showAnswerFeedback, setShowAnswerFeedback] = useState(null); // To show if the selected answer was correct

    const handleAnswerClick = (isCorrect) => {
        if (isCorrect) {
            setScore(score + 1);
        }
        setShowAnswerFeedback(isCorrect ? 'Correct!' : 'Incorrect!');

        // Delay before moving to the next question to allow the user to see feedback
        setTimeout(() => {
            setShowAnswerFeedback(null); // Reset feedback
            const nextQuestion = currentQuestion + 1;
            if (nextQuestion < questions.length) {
                setCurrentQuestion(nextQuestion);
            } else {
                setShowScore(true);
            }
        }, 1500); // 1.5-second delay before going to the next question
    };

    return (
        <div className="flex flex-col items-center justify-center h-180 bg-gray-100">
            {showScore ? (
                <div className="bg-white p-8 rounded-lg shadow-md text-center">
                    <h1 className="text-2xl font-bold mb-4">Quiz Completed!</h1>
                    <p className="text-lg">You scored {score} out of {questions.length}</p>
                </div>
            ) : (
                <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-xl">
                    <div className="mb-4">
                        <div className="text-lg font-medium text-gray-700">
                            Question {currentQuestion + 1} / {questions.length}
                        </div>
                        <div className="mt-2 text-xl font-semibold">
                            {questions[currentQuestion].question}
                        </div>
                    </div>
                    <div className="flex flex-col space-y-3">
                        {questions[currentQuestion].answers.map((answer, index) => (
                            <button
                                key={index}
                                onClick={() => handleAnswerClick(answer.isCorrect)}
                                className="button text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none"
                            >
                                {answer.text}
                            </button>
                        ))}
                    </div>
                    {showAnswerFeedback && (
                        <div className={`mt-4 text-xl font-bold ${showAnswerFeedback === 'Correct!' ? 'text-green-500' : 'text-red-500'}`}>
                            {showAnswerFeedback}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default AnalyticsQuiz;
