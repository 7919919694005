import React, { useEffect, useState } from 'react'
import PageLayout from '../../../../Layout/PageLayout/Index'
import SEOPageHero from '../SEOPageHero/Index'
import SEOPricing from '../../../../Components/SEOPricing/Index'
import mixpanel from 'mixpanel-browser'
import Process from '../../../../Components/Process/Index'
import { SiBiolink, SiFacebook, SiFiles, SiGoogle, SiGoogleanalytics, SiGooglesearchconsole, SiHtml5, SiInstagram, SiLinkedin, SiTiktok, SiTwitter, SiYoutube } from '@icons-pack/react-simple-icons'
import { LinkIcon } from '@heroicons/react/24/outline'
import SearchEngineSlider from '../../../../Components/SearchEngineSlider/Index'
import Slider from '../../../../Components/Slider/Index'
import CalendarModal from '../../../../Layout/CalendarModal/Index'
import LeadsForm from '../../../../Components/LeadsForm/Index'
import NationalSEOPricing from '../../../../Components/SEOPricing/National/Index'
import SEOFAQs from '../../../../Components/FAQs/SEOFAQs/Index'
import NationalSEOPricingTable from '../../../../Components/PricingTables/SEOPricingTable/National/Index'
import Popup from '../../../../Components/Popup/Index'
import SEOMetaHead from '../../../../Components/SEOMetaHead/Index'
import SocialProof from '../../../../Components/SocialProof/Index'

const SEONationalPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        mixpanel.track("SEO Service Page Visited")
    }, [])

    return (
        <PageLayout>
            <SEOMetaHead
                title="Expert National SEO Services - Enhance Your Reach in 2024"
                description="Expand your business nationally with Gurulabs' expert SEO strategies. Achieve top rankings and increase organic traffic with our comprehensive SEO services. Contact us to dominate national search results."

            />
            <section className="">
                <div className="max-w-7xl mx-auto py-8 lg:py-48 px-4">
                    <div className="flex flex-col lg:flex-row justify-between items-center">
                        <div className="w-full py-4 xl:w-8/12">
                            <h1 className="text-3xl lg:text-6xl my-1">Boost your
                                <span className="text-green-500 relative mx-2">
                                    National visibility
                                    <svg width="175" height="3" viewBox="0 0 175 3" fill="none" className="absolute hidden lg:block left-6">
                                        <path d="M1.43945 1.54395H173.903" stroke="#48BB78" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </span>
                                using our Search Engine Optimization Strategies</h1>
                            <p className="my-3">Gurulabs is your key to unlocking the potential of your website’s online visibility. Our experienced team of gurus use proven methods to increase your website’s search engine rankings and maximize your online traffic.</p>
                            <LeadsForm />
                        </div>
                        <div className="w-full py-4 xl:w-4/12">
                            <img src="/frontend/images/svg/icon-26.svg" className="mx-auto" alt="National SEO By Gurulabs" />
                        </div>
                    </div>
                </div>
            </section>
            <Slider />
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div className='block sm:flex justify-center items-center px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Come take a look at what seo services plans</span>
                                <span className="text-green-500 ml-2 relative">
                                    we offer!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <NationalSEOPricing modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
                    </div>
                </div>
            </section>
            <SocialProof />
            <section>
                <div className="max-w-5xl mx-auto py-24">
                    <div className='block sm:flex sm:flex-col justify-center items-center px-4'>
                        <h2 className="block text-3xl sm:text-4xl py-5 relative">
                            <span className="relative">Our Tech-Enabled</span>
                            <span className="text-green-500 ml-2 relative">
                                Search Engine Optimization Process
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-1/2 -mt-2 ml-16 hidden xl:block" width={238} height={17} fill="none"><path stroke="#48BB78" strokeLinecap="round" strokeWidth="2" d="M1.833 12.55c101.19 8.798 198.897-3.667 235.102-11" /></svg>
                        </h2>
                        <p className="text-base text-center">Our Lead generation process helps you identify potential customers or clients and gather their contact information to nurture them toward a sale.</p>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto pb-24">
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                        <Process
                            title="SEO Audit"
                            description="Starting with a comprehensive SEO audit, Gurulabs evaluates your current website to identify strengths, weaknesses, and opportunities for optimization. Our detailed assessment covers technical SEO, content, and backlink profiles to establish a baseline and craft a strategy that targets your specific business objectives and market position."
                            button="button-3"
                            Icon={SiGooglesearchconsole}
                        />
                        <Process
                            title="Keyword Research and Strategy"
                            description="Gurulabs performs extensive keyword research to understand the search landscape within your industry. We identify high-value keywords based on relevance, search volume, and competition level, forming a strategic foundation that drives content creation and optimization efforts to attract and engage your target audience effectively."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />
                        <Process
                            title="On-page SEO Optimization"
                            description="Our team meticulously optimizes on-page elements, including title tags, meta descriptions, headers, and images, ensuring they align with targeted SEO strategies. Gurulabs enhances your site’s usability and relevance, improving your search engine rankings while delivering a seamless user experience."
                            button="button-3"
                            Icon={SiGoogle}
                        />
                        <Process
                            title="Content Development"
                            description="Content is king in SEO, and Gurulabs specializes in creating SEO-driven content that not only ranks but resonates with your audience. From blog posts to whitepapers, we produce high-quality, engaging content tailored to your brand’s voice and user intent, positioning your business as a thought leader in your industry."
                            button="button-2"
                            Icon={SiHtml5}
                        />
                        <Process
                            title="Link Building Strategy"
                            description="Gurulabs uses a white-hat approach to link building, securing high-quality backlinks from authoritative websites to boost your site's authority and search engine rankings. Our strategies include guest blogging, influencer collaborations, and strategic partnerships, enhancing your online presence and driving organic traffic."
                            button="button-3"
                            Icon={LinkIcon}
                        />
                        <Process
                            title="Performance Tracking and Reporting"
                            description="With Gurulabs, transparency is paramount. We track the progress of your SEO campaign using advanced analytics tools to monitor key performance indicators like traffic, rankings, and conversions. Regular reports keep you informed of your SEO investment's impact, enabling continuous improvement and strategy refinement."
                            button="button-2"
                            Icon={SiGoogleanalytics}
                        />

                    </div>
                </div>
            </section>
            <section>
                <div className="bg-gray-50 py-12">
                    <div className="max-w-7xl mx-auto">
                        <div>
                            <div className='block sm:flex justify-center items-center px-4'>
                                <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                    <span className="relative">We're an affordable</span>
                                    <span className="text-green-500 ml-2 relative">
                                        National Search Engine Optimization
                                    </span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4 ml-16 hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                                </h2>
                            </div>
                            <p className="py-4 text-lg text-center">Our National SEO management process helps you identify your target audience, optimize your website and content strategies, and gather valuable insights to increase organic traffic and maximize your return on investment.</p>

                        </div>
                        <div className="px-4">
                            <div>
                                <p className="text-xl py-4 text-gray-700">At Gurulabs, we specialize in enhancing your brand's visibility across the nation through customized national SEO strategies. Our expert team employs the latest techniques and tools to ensure your SEO campaign is effective, aiming to maximize both reach and efficiency. Choosing Gurulabs means you're opting for a comprehensive SEO management experience designed to boost your search engine rankings and ROI extensively.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>National SEO Management Process</strong></p>

                                <p className="text-xl py-4 text-gray-700">Our national SEO services are structured to amplify your digital presence across the United States. Beginning with an in-depth analysis of your current SEO status, Gurulabs pinpoints crucial improvements. Our approach leverages advanced tools to refine and optimize your SEO tactics continually. Whether establishing a new SEO framework or boosting an existing one, our services are customized to meet your unique needs across various states and regions.</p>

                                <p className="text-xl py-4 text-gray-700">Gurulabs is dedicated to targeted optimization at a national level. We develop customized SEO strategies that align with your overarching business goals, focusing on precise keyword targeting, strategic content planning, and rigorous SEO audits. Our comprehensive service covers everything from on-page optimization to complex technical adjustments, ensuring your strategy is holistic and effective.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Expertise Across National SEO Dimensions</strong></p>

                                <p className="text-xl py-4 text-gray-700">As a leading national SEO agency, Gurulabs possesses deep expertise in all aspects of SEO, from technical nuances ensuring compliance with the latest search engine guidelines to content strategies that engage and convert on a national scale. Our holistic approach also covers both local and national SEO, helping you dominate search engine results across the country,detailed analysis to ongoing optimization, we provide comprehensive SEO solutions that deliver lasting results.</p>

                                <p className="text-xl py-4 text-gray-700">Our team ensures that every element of your digital strategy is optimized for national visibility. From in-depth keyword research to creating compelling content and establishing authoritative backlinks, we cover all the bases to elevate your national SEO strategy. Our goal is to deliver outstanding SEO services that exceed your expectations.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Customized Solutions for Diverse Business Needs</strong></p>

                                <p className="text-xl py-4 text-gray-700">Recognizing the distinctiveness of each business, we offer tailored SEO solutions specifically designed for your industry and target audience on a national scale. Whether enhancing eCommerce SEO to improve product visibility nationwide or developing robust strategies for B2B lead generation across the country, Gurulabs has the expertise to support your objectives. We work closely with you to understand your goals and devise a strategy that delivers tangible results.</p>

                                <p className="text-xl py-4 text-gray-700"><strong>Maximizing Your National Search Engine Visibility and ROI</strong></p>

                                <p className="text-xl py-4 text-gray-700">Success in national SEO is measured by tangible improvements in reach and outcomes. At Gurulabs, our focus is on maximizing your visibility across all major search engines and ensuring a high return on investment. We utilize a combination of proven strategies and innovative techniques to make your website not only visible but predominant across the nation. With Gurulabs, you can be confident that your SEO investment is being managed effectively, driving significant traffic and generating valuable leads.</p>

                                <p className="text-xl py-4 text-gray-700">In conclusion, opting for Gurulabs for national SEO management means choosing a comprehensive suite of services that enhance and simplify your online presence on a grand scale. From detailed analysis to continuous optimization, we offer holistic SEO solutions that achieve lasting impact. Partner with Gurulabs today and propel your website to the top of national search engine rankings.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <NationalSEOPricingTable />
            <SEOFAQs />
            <section>
                <div className="bg-amber-50 hero-reviews py-12">
                    <div className="max-w-7xl mx-auto overflow-hidden">
                        <div className='block sm:flex justify-center items-center py-16 px-4'>
                            <h2 className="block text-3xl sm:text-4xl py-5 relative">
                                <span className="relative">Hear from our Lead Gen</span>
                                <span className="text-green-500 ml-2 relative">
                                    Clients!
                                </span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute left-3/4  hidden xl:block" width={151} height={3} fill="none"><path stroke="#48BB78" strokeWidth={2} d="M0 1.85h150.5" /></svg>
                            </h2>
                        </div>
                        <SearchEngineSlider />
                    </div>
                </div>
            </section>
            <CalendarModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
            {/* <Popup /> */}
        </PageLayout>
    )
}
export default SEONationalPage
